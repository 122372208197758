import React from "react";
import theme from "../../styles/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faInstagramSquare,
  faLinkedin,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import "../../styles/footer.css";

const Footer = ({ bgcolor }) => {
  const defaultColor = theme.colors.mainGrayBackground;

  const color = bgcolor ? bgcolor : defaultColor;
  return (
    <div className="footer">
      <div className="left" style={{ margin: "2rem" }}>
        <div className=" child social-media-icons social-media-div">
          <a
            href="https://www.facebook.com/KhayrallahCenter"
            data-ua-label="Facebook"
          >
            <FontAwesomeIcon
              icon={faFacebookSquare}
              alt="Facebook"
              className="social-media-icon"
            />
          </a>

          <a href="https://twitter.com/KhayrallahNCSU" data-ua-label="Twitter">
            <FontAwesomeIcon
              icon={faTwitterSquare}
              alt="Twitter"
              className="social-media-icon"
            />
          </a>

          <a
            href="https://www.instagram.com/khayrallahcenter/"
            data-ua-label="Instagram"
          >
            <FontAwesomeIcon
              icon={faInstagramSquare}
              alt="Instagram"
              className="social-media-icon"
            />
          </a>

          <a
            href="https://www.linkedin.com/in/khayrallah-center-for-lebanese-diaspora-studies/"
            data-ua-label="LinkedIN"
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              alt="LinkedIN"
              className="social-media-icon"
            />
          </a>

          <a href="https://www.youtube.com/@LebaneseNC" data-ua-label="Youtube">
            <FontAwesomeIcon
              icon={faYoutubeSquare}
              alt="Youtube"
              className="social-media-icon"
            />
          </a>
        </div>

        <div className="copyright-div" dir="rtl">
          <div>
            .حقوق النشر © 2023 جامعة ولاية نورث كارولينا. جميع الحقوق محفوظة
            &nbsp;&nbsp;
            <a href="#" className="red-link">
              سياسة الخصوصية
            </a>
          </div>
        </div>
      </div>

      <div className="right" style={{ margin: "2rem" }}>
        <p className="kc-div"> مركز خيرالله لدراسات الانتشار اللبناني </p>
        <div className="row g-0">
          <div className="address-div">
            Campus Box 8013 <br />
            North Carolina State University <br />
            Raleigh, NC 27695-8108 <br />
            919.515.5058 <br />{" "}
            <a href="mailto:lebanesestudies@ncsu.edu" className="red-link">
              lebanesestudies@ncsu.edu
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
