import React from "react";

import Checkbox from "react-custom-checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Dash } from "../assets/icons/dash.svg";

import theme from "../styles/theme";

import "../styles/analytics.css";
import "../styles/browse.css";

import GenericModal from "./GenericModal";

import useBrowseFiltersPeriodicals from "../custom-hooks/useBrowseFiltersPeriodicals";

const BrowseFiltersPeriodicals = ({
  setBrowseResults,
  setCurrentPage,
  setLoading,
}) => {
  const {
    collections,
    minYear,
    maxYear,
    locations,
    sources,
    selectedCollections,
    selectedLocations,
    selectedSources,
    showMoreCollection,
    showMoreLocation,
    showMoreSource,
    selectedKeys1,
    selectedKeys2,
    selectedKeys3,
    unselectedKeys1,
    unselectedKeys2,
    unselectedKeys3,
    maxRowsToShow,
    handleCollectionCheckboxChange,
    handleLocationCheckboxChange,
    handleSourceCheckboxChange,
    handleCloseCollectionModal,
    handleCloseLocationModal,
    handleCloseSourceModal,
    handleFromYearChange,
    handleToYearChange,
    handleApply,
    clearFilters,
    setShowMoreCollection,
    fromYear,
    toYear,
    setShowMoreLocation,
    setShowMoreSource,
  } = useBrowseFiltersPeriodicals({
    setBrowseResults,
    setCurrentPage,
    setLoading,
  });

  return (
    <div style={{ background: theme.colors.mainGrayBackground, width: "100%" }}>
      <div className="row g-0">
        <div className="col g-0">
          <div
            className="heading"
            style={{
              padding: "3rem 0",
              justifyContent: "center",
            }}
          >
            <h2
              className="browse-header"
              style={{
                fontFamily: "Roboto-Slab",
                fontSize: "34px",
              }}
            >
              Browse our Collections
            </h2>
          </div>
        </div>
      </div>
      <div class="row" style={{ paddingBottom: "20px" }}>
        {/*--------------------COLLECTIONS-----------------------*/}
        <div class="col-sm new-heading">
          <div>Collections</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected collections first */}
            {selectedKeys1.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedCollections[key] || false}
                  onChange={(e) => handleCollectionCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected collections if there are remaining slots */}
            {selectedKeys1.length < maxRowsToShow &&
              unselectedKeys1
                .slice(0, maxRowsToShow - selectedKeys1.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedCollections[key] || false}
                      onChange={(e) => handleCollectionCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys1.length > maxRowsToShow - selectedKeys1.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreCollection(true);
                }}
              >
                {showMoreCollection ? "Less..." : "More..."}
              </span>
            )}

            <GenericModal
              title="Collections"
              show={showMoreCollection}
              onHide={handleCloseCollectionModal}
              data={collections}
              selectedData={selectedCollections}
              handleItemCheckboxChange={handleCollectionCheckboxChange}
            />
          </div>
        </div>

        {/*---------------------DATE---------------------------*/}
        <div class="col-sm new-heading">
          <div></div>
          <div
            className="date-input"
            style={{ fontSize: "1rem", color: "#212529" }}
          >
            <div className="from-to">
              <div
                style={{
                  fontSize: "1.3em",
                  fontWeight: "500",
                  color: "#12394d",
                }}
              >
                From
              </div>
              <div>
                <input
                  type="text"
                  placeholder={minYear === "" ? "YYYY" : minYear}
                  onChange={handleFromYearChange}
                  value={fromYear}
                  style={{ width: "5em", outline: "none", margin: "20px" }}
                />
              </div>
            </div>
            <Dash style={{ marginTop: "20px" }} />
            <div className="from-to">
              <div
                style={{
                  fontSize: "1.3em",
                  fontWeight: "500",
                  color: "#12394d",
                }}
              >
                To
              </div>
              <div>
                <input
                  type="text"
                  placeholder={maxYear === "" ? "YYYY" : maxYear}
                  onChange={handleToYearChange}
                  value={toYear}
                  style={{ width: "5em", outline: "none", margin: "20px" }}
                />
              </div>
            </div>
          </div>
        </div>

        {/*---------------------LOCATIONS---------------------------*/}
        <div class="col-sm new-heading">
          <div>Locations</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected collections first */}
            {selectedKeys2.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedLocations[key] || false}
                  onChange={(e) => handleLocationCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected collections if there are remaining slots */}
            {selectedKeys2.length < maxRowsToShow &&
              unselectedKeys2
                .slice(0, maxRowsToShow - selectedKeys2.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedLocations[key] || false}
                      onChange={(e) => handleLocationCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys2.length > maxRowsToShow - selectedKeys2.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreLocation(true);
                }}
              >
                {showMoreLocation ? "Less..." : "More..."}
              </span>
            )}

            <GenericModal
              title="Locations"
              show={showMoreLocation}
              onHide={handleCloseLocationModal}
              data={locations}
              selectedData={selectedLocations}
              handleItemCheckboxChange={handleLocationCheckboxChange}
            />
          </div>
        </div>

        {/*---------------------SOURCE----------------------------*/}
        <div class="col-sm new-heading">
          <div>Source</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected source first */}
            {selectedKeys3.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedSources[key] || false}
                  onChange={(e) => handleSourceCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected source if there are remaining slots */}
            {selectedKeys3.length < maxRowsToShow &&
              unselectedKeys3
                .slice(0, maxRowsToShow - selectedKeys3.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedSources[key] || false}
                      onChange={(e) => handleSourceCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys3.length > maxRowsToShow - selectedKeys3.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreSource(true);
                }}
              >
                {showMoreSource ? "Less..." : "More..."}
              </span>
            )}

            <GenericModal
              title="Sources"
              show={showMoreSource}
              onHide={handleCloseSourceModal}
              data={sources}
              selectedData={selectedSources}
              handleItemCheckboxChange={handleSourceCheckboxChange}
            />
          </div>
        </div>
      </div>

      <div className="row g-0">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "1rem",
          }}
        >
          <button
            className="btn rounded-0 apply-button"
            style={{
              margin: "3px 3px",
              width: "70px",
              height: "35px",
            }}
            onClick={handleApply}
          >
            <span>Apply</span>
          </button>

          <button
            className="btn rounded-0 clear-button"
            style={{
              margin: "3px 3px",
              width: "70px",
              height: "35px",
            }}
            onClick={clearFilters}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrowseFiltersPeriodicals;
