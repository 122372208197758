import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../styles/analytics.css";
import useFeedbackPopup from "../../custom-hooks/useFeedbackPopup";

const FeedbackPopupArabic = ({ show, handleClose, fingerprint }) => {
  const {
    feature_request,
    setFeature_request,
    collection_request,
    setCollection_request,
    handleSubmit,
  } = useFeedbackPopup({ show, handleClose, fingerprint });

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
      <div className="modal-header-style">
        <div className="modal-flex-box">
          <div>
            <h4 className="modal-title-style">تعليق</h4>
            <hr className="line-style"></hr>
          </div>

          <button
            type="button"
            className="close modal-close-style-ar"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <Modal.Body>
        <div
          style={{
            padding: "1rem",
            fontFamily: "Roboto",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <label style={{ textAlign: "right" }}>
            ما هي الميزات الجديدة التي ترغب في إضافتها إلى الموقع؟
            <textarea
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                resize: "vertical",
              }}
              value={feature_request}
              onChange={(e) => setFeature_request(e.target.value)}
            />
          </label>
          <label style={{ textAlign: "right" }}>
            هل لديك أي منشورات تود إضافتها إلى قاعدة البيانات؟ يرجى تقديم أكبر
            قدر ممكن من التفاصيل حول المنشور(العنوان، السنوات، مكان تواجد نسخ
            عنه، وما إلى ذلك)
            <input
              style={{
                width: "100%",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
              type="text"
              value={collection_request}
              onChange={(e) => setCollection_request(e.target.value)}
            />
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSubmit}
          className="active-button"
          style={{ marginRight: "25px" }}
        >
          يُقدِّم
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackPopupArabic;
