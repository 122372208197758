import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

import AlHoda from "../../assets/images/Al Hoda.jpg";
import Jurab from "../../assets/images/Jurab.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import "../../styles-arabic/learn-more.rtl.css";

import theme from "../../styles/theme";

const LearnMoreArabic = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="background">
        <div className="container g-0" style={{ backgroundColor: "#fff" }}>
          <div>
            <div className="row g-0 heading">
              <div className="col-2 back-button-div">
                <button
                  className="btn rounded-0"
                  onClick={() => window.history.back()}
                  style={{ backgroundColor: theme.colors.mainRed }}
                >
                  <FontAwesomeIcon
                    style={{ color: "#FFFFFF" }}
                    icon={faArrowLeft}
                  />
                  <span style={{ color: "#FFFFFF", marginLeft: "5px" }}>
                    العودة
                  </span>
                  &nbsp;&nbsp;
                </button>
              </div>
              <div
                className="col-8 g-0"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h2 className="learn-more-header">معلومات عن المشروع </h2>
              </div>
              <div className="col-2 g-0"></div>
            </div>
            <div className="row g-0" style={{ justifyContent: "center" }}>
              <hr className="line" />
            </div>
          </div>
          <div
            className="row g-0 about-section"
            style={{ padding: "15px" }}
            dir="rtl"
          >
            <div className="col-4 img-col">
              <img src={AlHoda} className="image" alt="صورة دورية" />
            </div>
            <div className="col-8 text-col">
              <div className="row">
                <p className="paragraph">
                  ترك المهاجرون العرب الأوائل إلى أمريكا الشمالية والجنوبية
                  إرثًا وتاريخًا ثريًا. ولقد تم تسجيل الكثير من تاريخهم في
                  ملايين الصفحات من الصحف والكتب والمجلات والمنشورات العربية.
                  ومع ذلك، كان من الصعب جداً التوصل إلى هذا السجل الغني لأنه كان
                  موزعا بين أرشيفات متفرقة موزعة بين بلدان ومدن مختلفة، ومخزّنا
                  في تقنيات قديمة يصعب التعامل معها. والآن، ولأول مرة في
                  التاريخ، تجعل قاعدة بيانات الصحف العربية التي صممها مركز خير
                  الله هذه السجلات قابلة للبحث الرقمي.
                  <br />
                  <br />
                  تتيح لك قاعدة البيانات البحث عن كلمات أو جمل محددة، وتصفية
                  البحث حسب سنة النشر، اسم الكتاب، الجريد أو المجلة، و/أو مكان
                  النشر. يمكنك البحث عن الأفراد وهم يتركون آثارهم عبر الصفحات،
                  ومتابعة النقاشات التي دارت في مجتمعات الأمريكيين العرب ما بين
                  ١٨٨٠ و ١٩٧٠، وقراءة الأعمال الأدبية، وملاحقة الأخبار السياسية
                  والاقتصادية، والدخول إلى عالم المهجر العربي الغني.{" "}
                  <i style={{ fontWeight: "bold" }}>المهجر</i>.
                </p>
              </div>
            </div>
          </div>
          <div className="videos" style={{ padding: "40px" }} dir="rtl">
            <div>
              <p
                className="paragraph"
                style={{ color: "#12394d", fontSize: "25px" }}
              >
                تعرف على فريق تطوير الأرشيف الرقمي
              </p>
              <div className="iframe-container">
                <iframe
                  src="https://www.youtube.com/embed/woidCJBHwV0"
                  title="مشغل فيديو YouTube"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <br></br>
            <br></br>
            <div>
              <p
                className="paragraph"
                style={{ color: "#12394d", fontSize: "25px" }}
              >
                تعلم كيفية استخدام قاعدة بيانات الصحف العربية
              </p>
              <div className="iframe-container">
                <iframe
                  src="https://www.youtube.com/embed/eC_j70SZTxw?si=zX530F7j_W-fuVVu"
                  title="مشغل فيديو YouTube"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div
            className="row g-0 last-section"
            style={{ padding: "15px" }}
            dir="rtl"
          >
            <div
              className="col-4 img-col child1"
              style={{ maxHeight: "250px" }}
            >
              <img src={Jurab} className="image" alt="صورة دورية" />
            </div>
            <div className="col-8 text-col child2">
              <div className="row g-0" style={{ padding: "10px" }}>
                <p className="paragraph">
                  خلال الأشهر القادمة، سنستمر في إضافة قدرات جديدة إلى هذه
                  الأداة بما في ذلك واجهة عربية وذكاء صناعي، وتحسين وتوسيع قاعدة
                  البيانات بمنشورات جديدة.
                  <br />
                  <br />
                  نرحب بدعمكم في الحفاظ على هذا الأرشيف المجاني وتوسيعه. يرجى
                  النظر في التبرع ب{" "}
                  <a href="https://lebanesestudies.ncsu.edu/donate/">
                    هدية صغيرة
                  </a>{" "}
                  للمساعدة في دعم جهودنا. كما يرجى إعلامنا بأي منشورات يمكننا
                  إضافتها إلى هذا الأرشيف. يمكنكم التواصل معنا على{" "}
                  <a href="mailto:lebanesestudies@ncsu.edu">
                    lebanesestudies@ncsu.edu
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer bgcolor="#fff" />
    </>
  );
};

export default LearnMoreArabic;
