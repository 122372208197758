import Select from "react-select";
import React, { useRef } from "react";
import "../../styles-arabic/search-input.rtl.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faKeyboard } from "@fortawesome/free-solid-svg-icons";
import arrowRightUp from "../../assets/icons/arrow-right-up.svg";
import Keyboard from "react-simple-keyboard";
import arabic from "simple-keyboard-layouts/build/layouts/arabic";
import "react-simple-keyboard/build/css/index.css";
import useSearchInput from "../../custom-hooks/useSearchInput";

const SearchInputArabic = ({
  searchString,
  setSearchResults,
  setSearchResultSets,
  setCurrentPage,
  setAnalytics,
  setLoading,
}) => {
  const autocompleteRef = useRef(null);

  const {
    searchInput,
    setIsHovered,
    keyboardVisibility,
    onChangeInput,
    handleSearch,
    toggleKeyboard,
    handleMatchTypeChange,
    keyboard,
    layoutName,
    onChangeArabicKeyboard,
    onKeyPress,
    Placeholder,
    DropdownIndicator,
    filterOptions,
    defaultValue,
    filterStyles,
    setSearchInput,
    suggestions,
    showSuggestions,
    setShowSuggestions,
    handleSuggestionClick,
  } = useSearchInput({
    searchString,
    setSearchResults,
    setSearchResultSets,
    setCurrentPage,
    setAnalytics,
    setLoading,
    autocompleteRef,
  });

  return (
    <>
      <div className="row g-0">
        <div className="col arabic-search-div">
          <div className="arabic-search-bar">
            <FontAwesomeIcon
              className="arabic-search-icon"
              icon={faSearch}
              onClick={handleSearch}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
            <div>
              <Select
                closeMenuOnSelect={false}
                components={{ Placeholder, DropdownIndicator }}
                placeholder={"Choose"}
                styles={filterStyles}
                options={filterOptions}
                blurInputOnSelect={true}
                defaultValue={defaultValue}
                onChange={handleMatchTypeChange}
              />
            </div>
            <input
              value={searchInput}
              className="arabic-text-input"
              type="text"
              placeholder={
                searchString
                  ? searchString
                  : "البحث عن طريق الكلمات الرئيسية أو التاريخ أو المجموعة"
              }
              onChange={onChangeInput}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.keyCode === 13) {
                  handleSearch();
                }
                if (event.key === "{bksp}")
                  setSearchInput(searchInput.slice(0, -1));
              }}
            />

            <span onClick={toggleKeyboard}>
              <FontAwesomeIcon
                className="arabic-keyboard-icon"
                icon={faKeyboard}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="row g-0" style={{ direction: "rtl" }}>
        {/* <div className="col"></div> */}
        <div ref={autocompleteRef} className="autocomplete-div">
          {/* Autocomplete suggestions */}
          {showSuggestions && suggestions && suggestions.length > 0 && (
            <ul className="autocomplete-suggestions">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="col arabic-text-below-search-box-div">
          <img
            className="arabic-right-up-arrow"
            src={arrowRightUp}
            alt="Arrow Right Up"
          ></img>
          <p>يمكنك استخدام لوحة المفاتيح العربية هذه</p>
        </div>
      </div>

      <div className="row arabic-keyboard">
        <div className="col-sm-2"></div>
        <div className="col-sm-8">
          {keyboardVisibility && (
            <Keyboard
              keyboardRef={keyboard.current}
              layoutName={layoutName}
              layout={arabic.layout}
              onChange={onChangeArabicKeyboard}
              onKeyPress={onKeyPress}
            />
          )}
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );
};

export default SearchInputArabic;
