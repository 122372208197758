import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home";
import HomeArabic from "./components/arabic/HomeArabic";
import SearchResults from "./components/SearchResults";
import SearchResultsArabic from "./components/arabic/SearchResultsArabic";
import PdfViewer from "./components/PdfViewer";
import BrowseResultsPeriodicals from "./components/BrowseResultsPeriodicals";
import BrowseResultsPeriodicalsArabic from "./components/arabic/BrowseResultsPeriodicalsArabic";
import Analytics from "./components/Analytics";
import AnalyticsArabic from "./components/arabic/AnalyticsArabic";
import LearnMore from "./components/Learn-More";
import LearnMoreArabic from "./components/arabic/LearnMoreArabic";
// import LoadingScreen from "./components/Loading";
import BrowseResultsBooks from "./components/BrowseResultsBooks";
import BrowseResultsBooksArabic from "./components/arabic/BrowseResultsBooksArabic";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const lang = localStorage.getItem("lang");
  const HomeComponent = lang === "ar" ? HomeArabic : Home;
  const SearchResultsComponent =
    lang === "ar" ? SearchResultsArabic : SearchResults;
  const LearnMoreComponent = lang === "ar" ? LearnMoreArabic : LearnMore;
  const BrowseResultsPeriodicalsComponent =
    lang === "ar" ? BrowseResultsPeriodicalsArabic : BrowseResultsPeriodicals;
  const BrowseResultsBooksComponent =
    lang === "ar" ? BrowseResultsBooksArabic : BrowseResultsBooks;
  const AnalyticsComponent = lang === "ar" ? AnalyticsArabic : Analytics;
  return (
    <>
      <Routes>
        <Route path="/home" element={<HomeComponent />} />
        <Route path="/search-results" element={<SearchResultsComponent />} />
        <Route path="/pdf-viewer" element={<PdfViewer />} />
        <Route
          path="/browse-periodicals"
          element={<BrowseResultsPeriodicalsComponent />}
        />
        <Route path="/browse-books" element={<BrowseResultsBooksComponent />} />
        <Route path="/analytics" element={<AnalyticsComponent />} />
        <Route path="/learn-more" element={<LearnMoreComponent />} />
        {/* <Route path="/loading" element={<LoadingScreen />} /> */}
        <Route path="*" element={<Navigate to="/home" />} />
        <Route />
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
