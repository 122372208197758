import { useState } from "react";
import { isArabicNumber, toEnglishNumber } from "../utils/arabicConversions";

const useYearInputValidation = (minYear, maxYear) => {
  const [year, setYear] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleYearChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = isArabicNumber(inputValue)
      ? toEnglishNumber(inputValue)
      : inputValue.replace(/\D/g, "");
    const truncatedValue = numericValue.slice(0, 4);

    if (/^\d*$/.test(truncatedValue)) {
      const numericYear = parseInt(truncatedValue);
      setYear(numericYear);
      if (numericYear >= minYear && numericYear <= maxYear) setIsValid(true);
      else setIsValid(false);
    } else {
      setYear("");
      // setIsValid(false);
    }
  };

  if (isNaN(year)) return { year: "", isValid, handleYearChange };

  return { year, isValid, handleYearChange };
};

export default useYearInputValidation;
