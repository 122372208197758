import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const browseAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/browse`,
});

export default api;
