import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isArabicNumber, toEnglishNumber } from "../utils/arabicConversions";
import { searchAPI } from "../axios/search-axios";
import { toast } from "react-toastify";
import { TrackGAEvent } from "../google-analytics/gautil";

const useLeftPane = ({
  setShow,
  setSearchResults,
  setSearchResultSets,
  setAnalytics,
  setCurrentPage,
  setAnalyticsModalOpen,
  setLoading,
}) => {
  const [formatVisible, setFormatVisible] = useState(false);
  const [dateVisible, setDateVisible] = useState(false);
  const [locationVisible, setLocationVisible] = useState(false);
  const [collectionVisible, setCollectionVisible] = useState(false);
  const [sourceVisible, setSourceVisible] = useState(false);

  const toggleFormat = () => {
    setFormatVisible(!formatVisible);
  };

  const toggleDate = () => {
    setDateVisible(!dateVisible);
  };

  const toggleLocation = () => {
    setLocationVisible(!locationVisible);
  };

  const toggleCollection = () => {
    setCollectionVisible(!collectionVisible);
  };

  const toggleSource = () => {
    setSourceVisible(!sourceVisible);
  };

  const [fromYear, setFromYear] = useState(null);
  const [toYear, setToYear] = useState(null);

  const handleFromYearChange = (event) => {
    let numericValue = "";
    const inputValue = event.target.value;
    if (isArabicNumber(inputValue)) {
      numericValue = toEnglishNumber(inputValue);
    } else {
      numericValue = inputValue.replace(/\D/g, "");
    }

    const truncatedValue = numericValue.slice(0, 4);
    setFromYear(truncatedValue);

    //Update filters state
    if (truncatedValue !== "")
      setFilters({ ...filters, from_year: truncatedValue });

    //if truncatedValue is empty string, remove from_year from filters
    if (truncatedValue === "") {
      const { from_year, ...rest } = filters;
      setFilters(rest);
    }
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const handleToYearChange = (event) => {
    let numericValue = "";
    const inputValue = event.target.value;
    if (isArabicNumber(inputValue)) {
      numericValue = toEnglishNumber(inputValue);
    } else {
      numericValue = inputValue.replace(/\D/g, "");
    }

    const truncatedValue = numericValue.slice(0, 4);
    setToYear(truncatedValue);

    if (truncatedValue !== "")
      setFilters({ ...filters, to_year: truncatedValue });

    if (truncatedValue === "") {
      const { to_year, ...rest } = filters;
      setFilters(rest);
    }
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const lang = localStorage.getItem("lang");
  const collNameKey = lang === "ar" ? "coll_name_arabic" : "coll_name";
  const collLocationKey =
    lang === "ar" ? "coll_location_arabic" : "coll_location";
  const collSourceKey = lang === "ar" ? "coll_source_arabic" : "coll_source";

  const getInitialFilters = () => {
    return {
      search_term: localStorage.getItem("searchTerm")
        ? localStorage.getItem("searchTerm").replace(/^"(.*)"$/, "$1")
        : "",
      match_type: localStorage.getItem("matchType")
        ? localStorage.getItem("matchType").replace(/^"(.*)"$/, "$1")
        : "any",
      sort_type: "desc",
      coll_type: [],
      [collNameKey]: [],
      [collLocationKey]: [],
      [collSourceKey]: [],
    };
  };

  const [filters, setFilters] = useState(getInitialFilters());

  const handleFormatCheckboxChange = (event, coll_type) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setFilters({
        ...filters,
        coll_type: Array.isArray(filters.coll_type)
          ? [...filters.coll_type, coll_type]
          : [coll_type],
      });
    } else {
      setFilters({
        ...filters,
        coll_type: Array.isArray(filters.coll_type)
          ? filters.coll_type.filter((x) => x !== coll_type)
          : [],
      });
    }
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const handleCollectionCheckboxChange = (event, collName) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setFilters({
        ...filters,
        [collNameKey]: Array.isArray(filters[collNameKey])
          ? [...filters[collNameKey], collName]
          : [collName],
      });
    } else {
      setFilters({
        ...filters,
        [collNameKey]: Array.isArray(filters[collNameKey])
          ? filters[collNameKey].filter((x) => x !== collName)
          : [],
      });
    }
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const handleSourceCheckboxChange = (event, collSource) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setFilters({
        ...filters,
        [collSourceKey]: Array.isArray(filters[collSourceKey])
          ? [...filters[collSourceKey], collSource]
          : [collSource],
      });
    } else {
      setFilters({
        ...filters,
        [collSourceKey]: Array.isArray(filters[collSourceKey])
          ? filters[collSourceKey].filter((x) => x !== collSource)
          : [],
      });
    }
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const handleLocationCheckboxChange = (event, collLocation) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setFilters({
        ...filters,
        [collLocationKey]: Array.isArray(filters[collLocationKey])
          ? [...filters[collLocationKey], collLocation]
          : [collLocation],
      });
    } else {
      setFilters({
        ...filters,
        [collLocationKey]: Array.isArray(filters[collLocationKey])
          ? filters[collLocationKey].filter((x) => x !== collLocation)
          : [],
      });
    }
    localStorage.setItem("filters", JSON.stringify(filters));
  };

  const navigate = useNavigate();

  const submitFilters = async () => {
    // Stringify the keys of the filters object
    // let filtersStringified = JSON.stringify(filters);
    // Check the filters object. If coll_name is empty array, remove it from the object.
    // Same for coll_location, coll_source, and coll_type

    setLoading(true);

    if (fromYear && toYear && fromYear > toYear) {
      if (lang !== "ar") {
        toast.error("Please enter a valid range of years", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
      } else {
        toast.error("الرجاء إدخال نطاق سنوات صالح", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          rtl: true,
        });
      }
      setLoading(false);
      return;
    }

    //Update the match type in filters
    setFilters({
      ...filters,
      match_type: localStorage.getItem("matchType")
        ? localStorage.getItem("matchType").replace(/^"(.*)"$/, "$1")
        : "any",
    });
    localStorage.setItem("filters", JSON.stringify(filters));

    let res = await searchAPI.put("", filters, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    TrackGAEvent("search", { search_term: filters.search_term });

    let results = res.data.docs;
    if (results.length === 0) {
      if (lang !== "ar") {
        toast.warn("No results found. Please expand your filters", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
      } else {
        toast.warn("لم يتم العثور على نتائج. يرجى توسيع الفلاتر الخاصة بك", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          rtl: true,
        });
      }
      setLoading(false);
      return;
    }

    res.data.docs.forEach((doc) => {
      doc.is_download_blocked = res.data.download_blocked_list.includes(
        doc.coll_identifier
      );
    });

    setSearchResults(res.data.docs);
    setSearchResultSets((prevSearchResultSets) => ({
      [1]: res.data.docs,
    }));
    setAnalytics(res.data.analytics);
    setCurrentPage(1);

    //Navigate to search results page
    navigate("/search-results", {
      state: {
        searchResults: res.data.docs,
        pageNumber: 1,
        searchString: localStorage.getItem("searchTerm")
          ? localStorage.getItem("searchTerm").replace(/^"(.*)"$/, "$1")
          : "",
      },
    });

    // Update filters state
    let newFilters = {
      ...filters,
      [collNameKey]: [],
      [collLocationKey]: [],
      [collSourceKey]: [],
      coll_type: [],
    };
    setFilters(newFilters);
    localStorage.setItem("filters", JSON.stringify(filters));

    // Uncheck all checkboxes
    let checkboxes = document.querySelectorAll("input[type=checkbox]");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    setLoading(false);

    if (typeof setShow === "function") setShow(false);
  };

  const clearFilters = async () => {
    setLoading(true);

    //Clear the filters object

    setFilters(getInitialFilters());
    localStorage.setItem("filters", JSON.stringify(filters));

    //Uncheck all checkboxes
    let checkboxes = document.querySelectorAll("input[type=checkbox]");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    setFromYear("");
    setToYear("");

    //Send empty filters to backend and update everything
    let res = await searchAPI.put("", getInitialFilters());
    TrackGAEvent("search", { search_term: filters.search_term });
    setSearchResults(res.data.docs);
    setSearchResultSets((prevSearchResultSets) => ({
      [1]: res.data.docs,
    }));
    setAnalytics(res.data.analytics);
    setCurrentPage(1);

    res.data.docs.forEach((doc) => {
      doc.is_download_blocked = res.data.download_blocked_list.includes(
        doc.coll_identifier
      );
    });

    navigate("/search-results", {
      state: {
        searchResults: res.data.docs,
        pageNumber: 1,
        searchString: localStorage
          .getItem("searchTerm")
          .replace(/^"(.*)"$/, "$1"),
      },
    });
    setLoading(false);
    if (typeof setShow === "function") setShow(false);
    // window.location.reload(true);
    // forceUpdate();
  };

  const [showMoreFormat, setShowMoreFormat] = useState(false);
  const [showMoreLocation, setShowMoreLocation] = useState(false);
  const [showMoreCollection, setShowMoreCollection] = useState(false);
  const [showMoreSource, setShowMoreSource] = useState(false);

  const toggleMoreFormat = () => {
    setShowMoreFormat(!showMoreFormat);
  };

  const toggleMoreLocation = () => {
    setShowMoreLocation(!showMoreLocation);
  };

  const toggleMoreCollection = () => {
    setShowMoreCollection(!showMoreCollection);
  };

  const toggleMoreSource = () => {
    setShowMoreSource(!showMoreSource);
  };

  const handleAnalyticsClick = () => {
    setAnalyticsModalOpen(true);
  };

  return {
    formatVisible,
    setFormatVisible,
    dateVisible,
    setDateVisible,
    locationVisible,
    setLocationVisible,
    collectionVisible,
    setCollectionVisible,
    sourceVisible,
    setSourceVisible,
    fromYear,
    setFromYear,
    toYear,
    setToYear,
    filters,
    setFilters,
    toggleFormat,
    toggleDate,
    toggleLocation,
    toggleCollection,
    toggleSource,
    handleFromYearChange,
    handleToYearChange,
    handleFormatCheckboxChange,
    handleCollectionCheckboxChange,
    handleSourceCheckboxChange,
    handleLocationCheckboxChange,
    submitFilters,
    clearFilters,
    showMoreFormat,
    setShowMoreFormat,
    showMoreLocation,
    setShowMoreLocation,
    showMoreCollection,
    setShowMoreCollection,
    showMoreSource,
    setShowMoreSource,
    handleAnalyticsClick,
    toggleMoreFormat,
    toggleMoreLocation,
    toggleMoreCollection,
    toggleMoreSource,
  };
};

export default useLeftPane;
