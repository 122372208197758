import React from "react";
import Header from "./Header";
import SearchInputArabic from "./SearchInputArabic";
import Footer from "./Footer";
import Loading from "../Loading";
import theme from "../../styles/theme";
import PdfViewerModalArabic from "./PdfViewerModalArabic";
import Modal from "react-bootstrap/Modal";
import FeedbackPopupArabic from "./FeedbackPopupArabic";
import calendar from "../../assets/icons/calendar.svg";
import compass from "../../assets/icons/compass.svg";
import multiple from "../../assets/icons/multiple-documents.svg";
import easel from "../../assets/icons/easel.svg";
import AnalyticsArabic from "./AnalyticsArabic";
import "../../styles-arabic/search-results.rtl.css";
import {
  toArabicNumber,
  convertToArabicDate,
} from "../../utils/arabicConversions";
import LeftPaneArabic from "./LeftPaneArabic";
import PageNumbersArabic from "./PageNumbersArabic";
import useSearchResults from "../../custom-hooks/useSearchResults";

const PAGINATION_RANGE = 2;

const SearchResultsArabic = () => {
  const {
    searchString,
    setSearchResults,
    setSearchResultSets,
    setAnalytics,
    currentPage,
    setCurrentPage,
    loading,
    setLoading,
    collTypeCounts,
    collNameIdentifier,
    collSourceCounts,
    collLocationCounts,
    displayedRows,
    emptyRowsCount,
    totalPages,
    handlePageChange,
    handleShow,
    handleClose,
    show,
    handleRowClick,
    selectedRow,
    modalOpen,
    handleCloseModal,
    handleAnalyticsClick,
    analyticsModalOpen,
    handleCloseAnalyticsModal,
    handleHeadingClick,
    sortArrowElement,
    isNewUser,
    fingerprint,
    showFeedbackPopup,
    handleCloseFeedbackPopup,
    setShow,
    setAnalyticsModalOpen,
    visitedRows,
  } = useSearchResults();

  return (
    <>
      <Loading isLoading={loading}>
        <div
          style={{ background: theme.colors.mainGrayBackground, width: "100%" }}
        >
          <Header />
          <div>
            <SearchInputArabic
              searchString={searchString}
              setSearchResults={setSearchResults}
              setSearchResultSets={setSearchResultSets}
              setAnalytics={setAnalytics}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
            />
          </div>
          <br></br>
          <div className="filters" dir="rtl">
            <div
              className="row g-0"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button className="filter-button" onClick={handleShow}>
                <b style={{ paddingLeft: "25px" }}>المرشحات</b>
              </button>
            </div>

            <button className="analytics-button" onClick={handleAnalyticsClick}>
              <img
                style={{
                  width: "20px",
                  height: "20px",
                }}
                alt=""
                src={easel}
              />
              <b>التحليلات</b>
            </button>
            <AnalyticsArabic
              show={analyticsModalOpen}
              handleClose={handleCloseAnalyticsModal}
            />
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton dir="rtl">
              <Modal.Title className="modal-title-style-arabic">
                المرشحات
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="row g-0 mt-4 ml-2 left-pane-modal">
                <LeftPaneArabic
                  btn={false}
                  setShow={setShow}
                  searchString={searchString}
                  setSearchResults={setSearchResults}
                  setSearchResultSets={setSearchResultSets}
                  setAnalytics={setAnalytics}
                  setCurrentPage={setCurrentPage}
                  collTypeCounts={collTypeCounts}
                  collNameIdentifier={collNameIdentifier}
                  collSourceCounts={collSourceCounts}
                  collLocationCounts={collLocationCounts}
                  setAnalyticsModalOpen={setAnalyticsModalOpen}
                  setLoading={setLoading}
                />
              </div>
            </Modal.Body>
          </Modal>

          <div className="row g-0 mt-4 ml-2 search-res-group">
            <div
              className="col-8 g-0 center-block"
              style={{ marginLeft: "15px", width: "60vw" }}
            >
              <div className="row g-0">
                <table className="arabic-table">
                  <colgroup>
                    <col style={{ width: "21%" }} />
                    <col style={{ width: "16%" }} />
                    <col style={{ width: "16%" }} />
                    <col style={{ width: "21%" }} />
                    <col style={{ width: "16%" }} />
                  </colgroup>
                  <thead className="arabic-table-head">
                    <tr>
                      <th
                        className="arabic-table-th-2"
                        style={{
                          paddingRight: "23px",
                          cursor: "pointer",
                          textAlign: "right",
                        }}
                        onClick={() => handleHeadingClick("coll_source_arabic")}
                      >
                        مصدر
                        <span>{sortArrowElement("coll_source_arabic")}</span>
                      </th>
                      <th
                        className="arabic-table-th-2"
                        style={{ cursor: "pointer", textAlign: "right" }}
                        onClick={() =>
                          handleHeadingClick("coll_location_arabic")
                        }
                      >
                        موقع
                        <span>{sortArrowElement("coll_location_arabic")}</span>
                      </th>
                      <th
                        className="arabic-table-th-2"
                        style={{ cursor: "pointer", textAlign: "right" }}
                        onClick={() => handleHeadingClick("date")}
                      >
                        تاريخ
                        <span>{sortArrowElement("date")}</span>
                      </th>
                      <th
                        className="arabic-table-th-2"
                        style={{ cursor: "pointer", textAlign: "right" }}
                        onClick={() => handleHeadingClick("coll_name_arabic")}
                      >
                        عنوان
                        <span>{sortArrowElement("coll_name_arabic")}</span>
                      </th>
                      <th
                        style={{ paddingRight: "23px", textAlign: "right" }}
                        className="arabic-table-th-2"
                      >
                        شكل
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedRows.map((row, index) => (
                      <React.Fragment key={index}>
                        <tr
                          onClick={() => handleRowClick(row)}
                          style={{
                            borderBottom: "1px solid #E5E5E5",
                            cursor: "pointer",
                          }}
                        >
                          <td
                            style={{ paddingRight: "2px", textAlign: "right" }}
                            className="arabic-ellipsis"
                          >
                            <img
                              src={multiple}
                              style={{
                                width: "16px",
                                height: "16px",
                                marginRight: "5px",
                              }}
                              alt="Multiple Icon"
                            />
                            {row.coll_source_arabic}
                          </td>
                          <td
                            className="arabic-ellipsis"
                            style={{ textAlign: "right" }}
                          >
                            <img
                              src={compass}
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "5px",
                              }}
                              alt="Compass Icon"
                            />
                            {row.coll_location_arabic}
                          </td>
                          <td
                            className="arabic-ellipsis2"
                            style={{ textAlign: "right" }}
                          >
                            <img
                              src={calendar}
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "5px",
                              }}
                              alt="Calendar Icon"
                            />
                            {convertToArabicDate(row.date)}
                          </td>
                          <td
                            className="arabic-ellipsis2"
                            style={{ textAlign: "right" }}
                          >
                            <div className="col g-0">
                              <div className="row g-0">
                                الصفحة{" "}
                                {toArabicNumber(row.page_number.toString())} من
                              </div>
                              {/* <div className="row g-0 title-text">
                                {row.coll_name_arabic}
                              </div> */}
                              <div
                                className={`row g-0 title-text ${visitedRows.has(row.AWS_link)
                                    ? "visited-row"
                                    : ""
                                  }`}
                              >
                                {row.coll_name_arabic}
                              </div>
                            </div>
                          </td>
                          <td
                            style={{ paddingRight: "23px", textAlign: "right" }}
                            className="arabic-ellipsis"
                          >
                            {row.coll_type === "book" ? "كتاب" : "مجلة"}
                          </td>
                        </tr>
                        {emptyRowsCount > 0 &&
                          index === displayedRows.length - 1 && (
                            <tr style={{ height: 65 * emptyRowsCount }}>
                              <td colSpan={5} />
                            </tr>
                          )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <PdfViewerModalArabic
                className="pdf-viewer-modal"
                show={modalOpen}
                handleClose={handleCloseModal}
                searchQuery={searchString}
                row={selectedRow}
              />
              {/* Analytics Modal */}
              <AnalyticsArabic
                show={analyticsModalOpen}
                handleClose={handleCloseAnalyticsModal}
              />
              <div className="row mt-5 mb-5 g-0">
                <div className="d-flex justify-content-center">
                  <PageNumbersArabic
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    PAGINATION_RANGE={PAGINATION_RANGE}
                  />
                </div>
              </div>
            </div>
            <div
              className="left-pane col-3 g-0"
              style={{ marginLeft: "60px", maxWidth: "20%" }}
            >
              <LeftPaneArabic
                btn={true}
                searchString={searchString}
                setSearchResults={setSearchResults}
                setSearchResultSets={setSearchResultSets}
                setAnalytics={setAnalytics}
                setCurrentPage={setCurrentPage}
                collTypeCounts={collTypeCounts}
                collNameIdentifier={collNameIdentifier}
                collSourceCounts={collSourceCounts}
                collLocationCounts={collLocationCounts}
                setAnalyticsModalOpen={setAnalyticsModalOpen}
                setLoading={setLoading}
              />
            </div>
          </div>
          {isNewUser !== null && isNewUser && (
            <FeedbackPopupArabic
              show={showFeedbackPopup}
              handleClose={handleCloseFeedbackPopup}
              fingerprint={fingerprint}
            />
          )}
        </div>
        <Footer bgcolor="#FFFFFF" />
      </Loading>
    </>
  );
};

export default SearchResultsArabic;
