import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "react-custom-checkbox";

const GenericModal = ({
  title,
  show,
  onHide,
  data,
  selectedData,
  handleItemCheckboxChange,
}) => {
  if (!show) return null;

  const prepareItemsForRendering = () => {
    const selectedKeys = Object.keys(selectedData);
    const selectedItems = selectedKeys.map((key) => ({
      key,
      value: data[key],
    }));
    const unselectedItems = Object.entries(data)
      .filter(([key]) => !selectedKeys.includes(key))
      .sort((a, b) => {
        let aValue = a ? a.toString() : "";
        let bValue = b ? b.toString() : "";
        return aValue.localeCompare(bValue);
      });

    return { selectedItems, unselectedItems };
  };

  const { selectedItems, unselectedItems } = prepareItemsForRendering();

  return (
    <div className="collection-modal-background">
      <div className="collection-modal">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="heading" style={{ paddingLeft: "0rem" }}>
            <h2 className="browse-header" style={{ color: "#12394d" }}>
              {title}
            </h2>
          </div>
          {/* Close button */}
          <button
            type="button"
            className="close modal-close-style"
            aria-label="Close"
            onClick={onHide}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/* Modal content */}
        <div
          style={{
            /* ... (styles for the scrollable list) */ maxHeight: "53vh",
            overflowY: "auto",
          }}
        >
          <table style={{ backgroundColor: "#FFF", width: "100%" }}>
            {/* Render selected checkboxes on top */}
            {selectedItems.map(({ key, value }) => (
              <tr style={{ border: "1px solid #ccc" }}>
                <div
                  key={key}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "8px",
                  }}
                >
                  <Checkbox
                    icon={
                      <div className="checkbox">
                        <FontAwesomeIcon
                          icon={faCheck}
                          height="10px"
                          width="10px"
                          size="lg"
                          color="#fff"
                        />
                      </div>
                    }
                    name="my-input"
                    checked={selectedData[key] || false}
                    onChange={(e) => handleItemCheckboxChange(e, key)}
                    size={14}
                    borderWidth={0.5}
                    style={{
                      borderRadius: "0",
                      borderColor: "#CCC",
                      backgroundColor: "#fff",
                    }}
                  />
                  <label
                    className="checkbox-label-browse"
                    style={{ maxWidth: "380px" }}
                  >
                    {value}
                  </label>
                </div>
              </tr>
            ))}
            {/* Render unselected checkboxes at the bottom */}
            {unselectedItems.map(([key, value]) => (
              <tr key={key} style={{ border: "1px solid #ccc" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "8px",
                  }}
                >
                  <Checkbox
                    icon={
                      <div className="checkbox">
                        <FontAwesomeIcon
                          icon={faCheck}
                          height="10px"
                          width="10px"
                          size="lg"
                          color="#fff"
                        />
                      </div>
                    }
                    name="my-input"
                    checked={selectedData[key] || false}
                    onChange={(e) => handleItemCheckboxChange(e, key)}
                    size={14}
                    borderWidth={0.5}
                    style={{
                      borderRadius: "0",
                      borderColor: "#CCC",
                      backgroundColor: "#fff",
                    }}
                  />

                  <label className="checkbox-label-browse">{value}</label>
                </div>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default GenericModal;
