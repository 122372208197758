import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { searchAPI } from "../axios/search-axios";
import useFeedbackMonitor from "../custom-hooks/useFeedbackMonitor";

const ITEMS_PER_PAGE = 10;
const MAX_PAGES_FROM_API = 100;

const useSearchResults = () => {
  const location = useLocation();
  const searchString = location.state ? location.state.searchString : "";

  const [searchResults, setSearchResults] = useState(
    location.state?.searchResults || []
  );

  const [searchResultSets, setSearchResultSets] = useState({});

  const {
    showFeedbackPopup,
    handleCloseFeedbackPopup,
    fingerprint,
    isNewUser,
  } = useFeedbackMonitor();

  //Filter Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchAdditionalResults = async (page) => {
    try {
      setLoading(true);
      const filters = JSON.parse(localStorage.getItem("filters"));
      let requestBody = {
        search_after: searchAfter,
        search_term: localStorage.getItem("searchTerm")
          ? localStorage.getItem("searchTerm").replace(/^"(.*)"$/, "$1")
          : "",
        match_type: localStorage.getItem("matchType")
          ? localStorage.getItem("matchType").replace(/^"(.*)"$/, "$1")
          : "any",
        sort_type: "desc",
      };
      if (filters)
        requestBody = {
          ...filters,
          ...requestBody,
        };
      const response = await searchAPI.put("", requestBody);
      const newSearchResults = response.data.docs;

      totalPages =
        totalPages + Math.ceil(newSearchResults.length / ITEMS_PER_PAGE);
      setSearchAfter(response.data.search_after);
      setSearchResultSets((prevSearchResultSets) => ({
        ...prevSearchResultSets,
        [page]: newSearchResults,
      }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching additional results:", error);
    }
  };

  useEffect(() => {}, [searchResultSets]);

  //Whenever location.state.searchResults changes, update the searchResultSets state
  useEffect(() => {
    if (location.state && location.state.searchResults) {
      setSearchResultSets((prevSearchResultSets) => ({
        [1]: location.state.searchResults,
      }));
    }
  }, [location.state]);

  //Headings sorting - whenever a user clicks a heading on the table, send existing filters / parameters plus "sort_param" (date, etc depending on what was clicked) and "sort_type" (asc/desc - asc on first click, desc on next, and so on) to the same /search endpoint. Take the new results, set them in searchResultSets, set the pagination page number to 1 and update the table with the new results.

  // Below state keeps track of sortParam( basically sort by column)
  const [sortParam, setSortParam] = useState("date");
  const [nextSortType, setNextSortType] = useState("desc");

  const fetchSortedData = async (curSortType, curSortParam) => {
    try {
      setLoading(true);
      const filters = JSON.parse(localStorage.getItem("filters"));
      let requestBody = {
        search_term: localStorage.getItem("searchTerm")
          ? localStorage.getItem("searchTerm").replace(/^"(.*)"$/, "$1")
          : "",
        match_type: localStorage.getItem("matchType")
          ? localStorage.getItem("matchType").replace(/^"(.*)"$/, "$1")
          : "any",
        sort_param: curSortType === "neutral" ? "date" : curSortParam,
        //In neutral state the curSortType is always asc
        sort_type: curSortType === "neutral" ? "asc" : curSortType,
      };
      if (curSortType !== "neutral") {
        requestBody.sort_type = curSortType;
      }
      // set filters
      if (filters)
        requestBody = {
          ...filters,
          ...requestBody,
        };
      const response = await searchAPI.put("", requestBody);
      const newSearchResults = response.data.docs;

      totalPages =
        totalPages + Math.ceil(newSearchResults.length / ITEMS_PER_PAGE);
      // setSearchAfter(response.data.search_after);
      setSearchResultSets((prevSearchResultSets) => ({
        [1]: newSearchResults,
      }));
      setSearchResults(newSearchResults);
      //Set location.state.searchResults to newSearchResults
      location.state.searchResults = newSearchResults;
      //Set localStorage searchResults to newSearchResults
      localStorage.setItem("searchResults", JSON.stringify(newSearchResults));
      setCurrentPage(1);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching additional results:", error);
    }
  };

  const handleHeadingClick = async (columnName) => {
    // neutral -> asc -> desc -> neutral -> asc -> desc -> neutral -> ...
    let newNextSortType;
    if (sortParam === columnName) {
      if (nextSortType === "asc") newNextSortType = "desc";
      else if (nextSortType === "desc") newNextSortType = "neutral";
      else newNextSortType = "asc";
      await fetchSortedData(nextSortType, columnName);
    } else {
      setSortParam(columnName);
      await fetchSortedData("asc", columnName);
      newNextSortType = "desc";
    }
    setNextSortType(newNextSortType);
  };

  const sortArrowElement = (col_type) => {
    // asc -> desc -> neutral -> asc -> desc -> neutral -> ...
    if (col_type === sortParam) {
      if (nextSortType === "asc") {
        // current neutral
        return " ▽△";
      } else if (nextSortType === "desc") {
        // current asc
        return " ▲";
      } else {
        // next neutral, current desc
        return " ▼";
      }
    } else {
      return " △▽";
    }
  };

  const [searchAfter, setSearchAfter] = useState(
    location.state?.searchAfter || ""
  );

  // Loading
  const [loading, setLoading] = useState(false);

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [analytics, setAnalytics] = useState(location.state?.analytics || {});

  const lastKey = Math.max(...Object.keys(searchResultSets).map(Number));

  let totalPages = 0;
  if (searchResultSets[lastKey])
    totalPages =
      lastKey -
      1 +
      Math.ceil(searchResultSets[lastKey].length / ITEMS_PER_PAGE);

  //For current page
  let startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  let endIndex = startIndex + ITEMS_PER_PAGE;
  let displayedRows = [];
  let currentSearchResults;
  let hashMapKey =
    Math.floor(currentPage / MAX_PAGES_FROM_API) * MAX_PAGES_FROM_API + 1;
  if (searchResultSets[hashMapKey] && currentPage >= hashMapKey) {
    currentSearchResults = searchResultSets[hashMapKey];
    localStorage.setItem("searchResults", JSON.stringify(currentSearchResults));
    startIndex = (currentPage - hashMapKey) * ITEMS_PER_PAGE;
    endIndex = startIndex + ITEMS_PER_PAGE;
    displayedRows = currentSearchResults?.slice(startIndex, endIndex);
  }
  //For the bottleneck pages where data loads
  else if (currentPage === hashMapKey - 1) {
    let prevHashmapKey = hashMapKey - MAX_PAGES_FROM_API;
    currentSearchResults = searchResultSets[prevHashmapKey];
    localStorage.setItem("searchResults", JSON.stringify(currentSearchResults));
    startIndex = (currentPage - prevHashmapKey) * ITEMS_PER_PAGE;
    endIndex = startIndex + ITEMS_PER_PAGE;
    displayedRows = currentSearchResults?.slice(startIndex, endIndex);
  }

  const emptyRowsCount = ITEMS_PER_PAGE - displayedRows.length;

  const handlePageChange = (page) => {
    setCurrentPage(page);

    const hashMapKey =
      Math.floor(page / MAX_PAGES_FROM_API) * MAX_PAGES_FROM_API + 1;
    if (!searchResultSets[hashMapKey]) {
      fetchAdditionalResults(hashMapKey);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    setSearchResultSets((prevSearchResultSets) => ({
      [1]: searchResults || location.state.searchResults,
    }));
  }, []);

  useEffect(() => {
    // Retrieve analytics data from localStorage and update the state
    const localStorageAnalytics = JSON.parse(localStorage.getItem("analytics"));
    setAnalytics(localStorageAnalytics);

    // Set the searchItemsSet flag to false before navigating away from the page
    const handleBeforeUnload = (event) => {
      localStorage.setItem("searchItemsSet", false);
      // localStorage.setItem("searchResults", null);

      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  //PDF Modal

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  //Analytics modal
  const [analyticsModalOpen, setAnalyticsModalOpen] = useState(false);

  // For making clicked rows purple
  const [visitedRows, setVisitedRows] = useState(() => {
    const storedVisitedRows =
      JSON.parse(localStorage.getItem("visitedRows")) || [];
    return new Set(storedVisitedRows);
  });

  // To make visited rows persist across sessions

  useEffect(() => {
    const storedVisitedRows =
      JSON.parse(localStorage.getItem("visitedRows")) || [];
    setVisitedRows(new Set(storedVisitedRows));
  }, []);

  useEffect(() => {
    localStorage.setItem("visitedRows", JSON.stringify([...visitedRows]));
  }, [visitedRows]);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
    setVisitedRows((prev) => {
      const newSet = new Set(prev);
      newSet.add(row.AWS_link);
      localStorage.setItem("visitedRows", JSON.stringify([...newSet]));
      return newSet;
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseAnalyticsModal = () => {
    setAnalyticsModalOpen(false);
  };

  useEffect(() => {}, [analytics, searchResults]);

  //Loop through searchResults, count the number of each collection type, and store in an array of objects
  const collTypeCounts = [];
  const storedResults = JSON.parse(localStorage.getItem("searchResults"));

  // const updatedResults = searchResults;
  let updatedResults = JSON.parse(localStorage.getItem("analytics"));
  updatedResults = analytics;

  //! Format

  if (
    updatedResults &&
    updatedResults.coll_type &&
    updatedResults.coll_type.buckets
  ) {
    let formatResults = updatedResults.coll_type.buckets;
    formatResults.forEach((result) => {
      const collType = result.key;
      const count = result.doc_count;
      collTypeCounts.push({ coll_type: collType, count: count });
    });
  }

  const lang = localStorage.getItem("lang");
  const isArabic = lang === "ar";

  //! Collection
  const collNameIdentifier = [];
  const collectionKey = isArabic
    ? "analytics_per_collection_arabic"
    : "analytics_per_collection";
  if (
    updatedResults &&
    updatedResults[collectionKey] &&
    updatedResults[collectionKey].buckets
  ) {
    updatedResults[collectionKey].buckets.forEach((result) => {
      const collName = result.key;
      const count = result.doc_count;
      collNameIdentifier.push({
        [isArabic ? "coll_name_arabic" : "coll_name"]: collName,
        count,
      });
    });
  }

  //! Source
  const collSourceCounts = [];
  const sourceKey = isArabic ? "coll_source_arabic" : "coll_source";
  if (
    updatedResults &&
    updatedResults[sourceKey] &&
    updatedResults[sourceKey].buckets
  ) {
    updatedResults[sourceKey].buckets.forEach((result) => {
      const collSource = result.key;
      const count = result.doc_count;
      collSourceCounts.push({
        [isArabic ? "coll_source_arabic" : "coll_source"]: collSource,
        count,
      });
    });
  }

  //! Location
  const collLocationCounts = [];
  const locationKey = isArabic ? "coll_location_arabic" : "coll_location";
  if (
    updatedResults &&
    updatedResults[locationKey] &&
    updatedResults[locationKey].buckets
  ) {
    updatedResults[locationKey].buckets.forEach((result) => {
      const collLocation = result.key;
      const count = result.doc_count;
      collLocationCounts.push({
        [isArabic ? "coll_location_arabic" : "coll_location"]: collLocation,
        count,
      });
    });
  }

  //Set the searchItemsSet flag to false before navigating away from the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.setItem("searchItemsSet", false);
      // localStorage.setItem("searchResults", null);

      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("searchItemsSet") === "false") {
      localStorage.setItem("searchResults", JSON.stringify(searchResults));
      localStorage.setItem("searchItemsSet", true);
    }
  }, [searchResults]);

  useEffect(() => {}, [storedResults, searchResults, updatedResults]);

  const handleAnalyticsClick = () => {
    setAnalyticsModalOpen(true);
  };

  return {
    searchResults,
    setSearchResults,
    searchResultSets,
    setSearchResultSets,
    handleHeadingClick,
    sortArrowElement,
    handlePageChange,
    currentPage,
    totalPages,
    displayedRows,
    emptyRowsCount,
    handleRowClick,
    modalOpen,
    selectedRow,
    handleCloseModal,
    handleAnalyticsClick,
    analyticsModalOpen,
    handleCloseAnalyticsModal,
    collTypeCounts,
    collNameIdentifier,
    collSourceCounts,
    collLocationCounts,
    handleShow,
    show,
    handleClose,
    handleAnalyticsClick,
    analyticsModalOpen,
    handleCloseAnalyticsModal,
    handleRowClick,
    modalOpen,
    selectedRow,
    handleCloseModal,
    handlePageChange,
    currentPage,
    totalPages,
    displayedRows,
    emptyRowsCount,
    handleHeadingClick,
    sortArrowElement,
    searchAfter,
    setSearchAfter,
    loading,
    setLoading,
    handleAnalyticsClick,
    analyticsModalOpen,
    handleCloseAnalyticsModal,
    collTypeCounts,
    collNameIdentifier,
    collSourceCounts,
    collLocationCounts,
    handleShow,
    show,
    handleClose,
    handleAnalyticsClick,
    analyticsModalOpen,
    handleCloseAnalyticsModal,
    handleRowClick,
    modalOpen,
    selectedRow,
    handleCloseModal,
    handlePageChange,
    currentPage,
    totalPages,
    displayedRows,
    emptyRowsCount,
    handleHeadingClick,
    sortArrowElement,
    searchAfter,
    setSearchAfter,
    loading,
    setLoading,
    handleAnalyticsClick,
    analyticsModalOpen,
    handleCloseAnalyticsModal,
    collTypeCounts,
    collNameIdentifier,
    collSourceCounts,
    collLocationCounts,
    fingerprint,
    isNewUser,
    setShow,
    setAnalyticsModalOpen,
    setAnalytics,
    setCurrentPage,
    searchString,
    visitedRows,
  };
};

export default useSearchResults;
