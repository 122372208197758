import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { browseAPI } from "../axios/axios";

import { toast } from "react-toastify";
import useYearInputValidation from "../custom-hooks/useYearInputValidation";

const useBrowseFiltersPeriodicals = ({
  setBrowseResults,
  setCurrentPage,
  setLoading,
}) => {
  const [collections, setCollections] = useState([]);
  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");
  const [locations, setLocations] = useState([]);
  const [sources, setSources] = useState([]);

  const [selectedCollections, setSelectedCollections] = useState({});
  const [selectedLocations, setSelectedLocations] = useState({});
  const [selectedSources, setSelectedSources] = useState({});

  const [showMoreCollection, setShowMoreCollection] = useState(false);
  const [showMoreLocation, setShowMoreLocation] = useState(false);
  const [showMoreSource, setShowMoreSource] = useState(false);

  const handleCloseCollectionModal = () => {
    setShowMoreCollection(false);
  };

  const handleCloseLocationModal = () => {
    setShowMoreLocation(false);
  };

  const handleCloseSourceModal = () => {
    setShowMoreSource(false);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const coll_type = location.state?.coll_type;
  let params = { coll_type: coll_type };
  const [selectedCollType, setSelectedCollType] = useState("periodical");

  const lang = localStorage.getItem("lang");
  const isArabic = lang === "ar";

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await browseAPI.post("/metadata/periodical", params);

        setCollections(
          isArabic ? res.data.coll_name_arabic : res.data.coll_name
        );
        setLocations(
          isArabic ? res.data.coll_location_arabic : res.data.coll_location
        );
        setMinYear(res.data.from_year);
        setMaxYear(res.data.to_year);
        setSources(
          isArabic ? res.data.coll_source_arabic : res.data.coll_source
        );
        setTimeout(() => {}, 1000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const maxRowsToShow = 3;

  const {
    year: fromYear,
    isValid: isFromYearValid,
    handleYearChange: handleFromYearChange,
  } = useYearInputValidation(minYear, maxYear);
  const {
    year: toYear,
    isValid: isToYearValid,
    handleYearChange: handleToYearChange,
  } = useYearInputValidation(minYear, maxYear);

  // Separate the selected and unselected collections
  const tempSelectedKeys1 = Object.keys(selectedCollections);
  const selectedKeys1 = tempSelectedKeys1.map((key) => ({
    key,
    value: collections[key],
  }));
  const unselectedKeys1 = Object.entries(collections)
    .filter(([key]) => !selectedCollections[key])
    .sort((a, b) => a[1].localeCompare(b[1]));

  // Separate the selected and unselected locations
  const tempSelectedKeys2 = Object.keys(selectedLocations);
  const selectedKeys2 = tempSelectedKeys2.map((key) => ({
    key,
    value: locations[key],
  }));
  const unselectedKeys2 = Object.entries(locations)
    .filter(([key]) => !selectedLocations[key])
    .sort((a, b) => a[1].localeCompare(b[1]));

  // Separate the selected and unselected sources
  const tempSelectedKeys3 = Object.keys(selectedSources);
  const selectedKeys3 = tempSelectedKeys3.map((key) => ({
    key,
    value: sources[key],
  }));
  const unselectedKeys3 = Object.entries(sources)
    .filter(([key]) => !selectedSources[key])
    .sort((a, b) => a[1].localeCompare(b[1]));

  const handleCollectionCheckboxChange = (event, index) => {
    setSelectedCollections((prevSelectedCollections) => {
      const updatedSelectedCollections = { ...prevSelectedCollections };
      if (event) {
        updatedSelectedCollections[index] = true;
      } else {
        delete updatedSelectedCollections[index];
      }
      return updatedSelectedCollections;
    });
  };

  const handleLocationCheckboxChange = (event, index) => {
    setSelectedLocations((prevSelectedLocations) => {
      const updatedSelectedLocations = { ...prevSelectedLocations };
      if (event) {
        updatedSelectedLocations[index] = true;
      } else {
        delete updatedSelectedLocations[index];
      }
      return updatedSelectedLocations;
    });
  };

  const handleSourceCheckboxChange = async (event, index) => {
    setSelectedSources((prevSelectedSources) => {
      const updatedSelectedSources = { ...prevSelectedSources };
      if (event) {
        updatedSelectedSources[index] = true;
      } else {
        delete updatedSelectedSources[index];
      }
      return updatedSelectedSources;
    });
  };

  const handleApply = async () => {
    if (parseInt(fromYear) > parseInt(toYear)) {
      toast.error(
        isArabic
          ? "الرجاء إدخال نطاق سنوات صالح"
          : "Please enter a valid range of years",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          rtl: isArabic,
          draggable: true,
        }
      );
      return;
    }

    setLoading(true);
    const params = {
      coll_type: selectedCollType,
      coll_name: isArabic ? undefined : getList(collections, selectedKeys1),
      coll_name_arabic: isArabic
        ? getList(collections, selectedKeys1)
        : undefined,
      coll_location: isArabic ? undefined : getList(locations, selectedKeys2),
      coll_location_arabic: isArabic
        ? getList(locations, selectedKeys2)
        : undefined,
      coll_source: isArabic ? undefined : getList(sources, selectedKeys3),
      coll_source_arabic: isArabic
        ? getList(sources, selectedKeys3)
        : undefined,
      from_year: parseInt(fromYear),
      to_year: parseInt(toYear),
    };

    try {
      let res = await browseAPI.post("/data/periodical", params);

      if (res.data.length === 0) {
        toast.warn(isArabic ? "لا توجد نتائج" : "No results found", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          rtl: isArabic,
          draggable: true,
        });
      } else {
        localStorage.setItem("browseItemsSet", JSON.stringify(false));
        setBrowseResults && setBrowseResults(res.data);
        setCurrentPage && setCurrentPage(1);

        navigate("/browse-periodicals", {
          state: {
            coll_type: coll_type,
            coll_name: getList(collections, selectedKeys1),
            coll_location: getList(locations, selectedKeys2),
            coll_source: getList(sources, selectedKeys3),
            from_year: parseInt(fromYear),
            to_year: parseInt(toYear),
            browseResults: res.data,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const clearFilters = async () => {
    setLoading(true);

    setSelectedCollections({});
    setSelectedLocations({});
    setSelectedSources({});
    handleFromYearChange({ target: { value: "" } });
    handleToYearChange({ target: { value: "" } });

    const params = {
      coll_type: selectedCollType,
    };

    try {
      let res = await browseAPI.post("/data/periodical", params);

      if (res.data.length === 0) {
        toast.warn(isArabic ? "لا توجد نتائج" : "No results found", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          rtl: isArabic,
          draggable: true,
        });
      } else {
        // If results are found, proceed with setting state and navigating
        localStorage.setItem("browseItemsSet", JSON.stringify(false));
        if (setBrowseResults) {
          setBrowseResults(res.data);
        }

        if (setCurrentPage) {
          setCurrentPage(1);
        }

        navigate("/browse-periodicals", {
          state: {
            coll_type: coll_type,
            coll_name: getList(collections, selectedKeys1),
            coll_location: getList(locations, selectedKeys2),
            coll_source: getList(sources, selectedKeys3),
            from_year: parseInt(fromYear),
            to_year: parseInt(toYear),
            browseResults: res.data,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getList = (dictionary, selectedKeys) => {
    var list = [];
    selectedKeys.forEach(({ key, value }) => list.push(dictionary[key]));
    return list;
  };

  return {
    collections,
    locations,
    sources,
    selectedCollections,
    selectedLocations,
    selectedSources,
    showMoreCollection,
    showMoreLocation,
    showMoreSource,
    handleCloseCollectionModal,
    handleCloseLocationModal,
    handleCloseSourceModal,
    navigate,
    location,
    coll_type,
    selectedCollType,
    setSelectedCollType,
    isArabic,
    maxRowsToShow,
    fromYear,
    isFromYearValid,
    handleFromYearChange,
    toYear,
    isToYearValid,
    handleToYearChange,
    handleCollectionCheckboxChange,
    handleLocationCheckboxChange,
    handleSourceCheckboxChange,
    handleApply,
    clearFilters,
    selectedKeys1,
    unselectedKeys1,
    selectedKeys2,
    unselectedKeys2,
    selectedKeys3,
    unselectedKeys3,
    setShowMoreCollection,
    setShowMoreLocation,
    setShowMoreSource,
    minYear,
    maxYear,
  };
};

export default useBrowseFiltersPeriodicals;
