import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Loading from "../Loading";
import BrowseFiltersPeriodicalsArabic from "./BrowseFiltersPeriodicalsArabic";
import theme from "../../styles/theme";
import PdfViewerModalArabic from "./PdfViewerModalArabic";
import FeedbackPopupArabic from "./FeedbackPopupArabic";
import calendar from "../../assets/icons/calendar.svg";
import compass from "../../assets/icons/compass.svg";
import multiple from "../../assets/icons/multiple-documents.svg";
import "../../styles-arabic/search-results.rtl.css";

import { convertToArabicDate } from "../../utils/arabicConversions";

import PageNumbersArabic from "./PageNumbersArabic";
import useBrowseResults from "../../custom-hooks/useBrowseResults";

const PAGINATION_RANGE = 2;

const BrowseResultsPeriodicalsArabic = () => {
  const {
    browseResults,
    setBrowseResults,
    loading,
    setLoading,
    currentPage,
    setCurrentPage,
    totalPages,
    displayedRows,
    handlePageChange,
    modalOpen,
    selectedRow,
    handleRowClick,
    handleCloseModal,
    showFeedbackPopup,
    handleCloseFeedbackPopup,
    fingerprint,
    isNewUser,
    emptyRowsCount,
  } = useBrowseResults();

  return (
    <>
      <Loading isLoading={loading}>
        <div
          style={{
            background: theme.colors.mainGrayBackground,
            width: "100%",
          }}
        >
          <Header />
          <div>
            {/* <SearchInput
              searchString={searchString}
              setSearchResults={setSearchResults}
              setAnalytics={setAnalytics}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
            /> */}
            <BrowseFiltersPeriodicalsArabic
              setBrowseResults={setBrowseResults}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
            />
          </div>
          {browseResults && browseResults.length > 0 && (
            <div className="row g-0 mt-4 ml-2 mr-2">
              <div className="col g-0" style={{ margin: "0 15px" }}>
                <div className="row g-0">
                  <table className="arabic-table" dir="rtl">
                    <thead className="arabic-table-head">
                      <tr>
                        <th
                          style={{ paddingRight: "23px" }}
                          className="arabic-table-th"
                        >
                          الصيغة
                        </th>
                        <th className="arabic-table-th">العنوان</th>
                        <th className="arabic-table-th">التاريخ</th>
                        <th className="arabic-table-th">الموقع</th>
                        <th
                          style={{ paddingLeft: "23px" }}
                          className="arabic-table-th"
                        >
                          المصدر
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedRows.map((row, index) => (
                        <React.Fragment key={index}>
                          <tr
                            onClick={() => handleRowClick(row)}
                            style={{
                              borderBottom: "1px solid #E5E5E5",
                              cursor: "pointer",
                            }}
                          >
                            <td
                              style={{ paddingRight: "23px" }}
                              className="arabic-ellipsis"
                            >
                              {row.coll_type === "book" ? "كتاب" : "مجلة"}
                            </td>
                            <td className="arabic-ellipsis2">
                              <div className="col g-0">
                                <div className="row g-0 arabic-title-text">
                                  {row.coll_name_arabic}
                                </div>
                              </div>
                            </td>
                            <td>
                              <img
                                src={calendar}
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginLeft: "5px",
                                }}
                                alt="Calendar Icon"
                              />
                              {row.coll_type === "book"
                                ? convertToArabicDate(row.date_of_publication)
                                : convertToArabicDate(row.date_of_journal)}
                            </td>
                            <td className="arabic-ellipsis">
                              <img
                                src={compass}
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginLeft: "5px",
                                }}
                                alt="Compass Icon"
                              />
                              {row.coll_location_arabic}
                            </td>
                            <td
                              style={{ paddingLeft: "23px" }}
                              className="arabic-ellipsis"
                            >
                              <img
                                src={multiple}
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginLeft: "5px",
                                }}
                                alt="Multiple Icon"
                              />
                              {row.coll_source_arabic}
                            </td>
                          </tr>
                          {emptyRowsCount > 0 &&
                            index === displayedRows.length - 1 && (
                              <tr
                                style={{
                                  height: 65 * emptyRowsCount,
                                }}
                              >
                                <td colSpan={5} />
                              </tr>
                            )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                <PdfViewerModalArabic
                  className="pdf-viewer-modal"
                  show={modalOpen}
                  handleClose={handleCloseModal}
                  searchQuery={""}
                  row={selectedRow}
                />
                <div className="row mt-5 mb-5 g-0">
                  <div className="d-flex justify-content-center">
                    <PageNumbersArabic
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                      PAGINATION_RANGE={PAGINATION_RANGE}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {isNewUser !== null && isNewUser && (
          <FeedbackPopupArabic
            show={showFeedbackPopup}
            handleClose={handleCloseFeedbackPopup}
            fingerprint={fingerprint}
          />
        )}
        <Footer bgcolor="#FFFFFF" />
      </Loading>
    </>
  );
};

export default BrowseResultsPeriodicalsArabic;
