import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Loading from "./Loading";
import BrowseFiltersPeriodicals from "./BrowseFiltersPeriodicals";
import theme from "../styles/theme";
import PdfViewerModal from "./PdfViewerModal";
import FeedbackPopup from "./FeedbackPopup";
import calendar from "../assets/icons/calendar.svg";
import compass from "../assets/icons/compass.svg";
import multiple from "../assets/icons/multiple-documents.svg";
import "../styles/search-results.css";
import PageNumbers from "./PageNumbers";
import useBrowseResults from "../custom-hooks/useBrowseResults";

const PAGINATION_RANGE = 2;

const BrowseResultsPeriodicals = () => {
  const {
    browseResults,
    setBrowseResults,
    loading,
    setLoading,
    currentPage,
    setCurrentPage,
    totalPages,
    displayedRows,
    handlePageChange,
    modalOpen,
    selectedRow,
    handleRowClick,
    handleCloseModal,
    showFeedbackPopup,
    handleCloseFeedbackPopup,
    fingerprint,
    isNewUser,
    emptyRowsCount,
  } = useBrowseResults();

  return (
    <>
      <Loading isLoading={loading}>
        <div
          style={{ background: theme.colors.mainGrayBackground, width: "100%" }}
        >
          <Header />
          <div>
            {/* <SearchInput
              searchString={searchString}
              setSearchResults={setSearchResults}
              setAnalytics={setAnalytics}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
            /> */}
            <BrowseFiltersPeriodicals
              setBrowseResults={setBrowseResults}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
            />
          </div>
          {browseResults && browseResults.length > 0 && (
            <div className="row g-0 mt-4 ml-2 mr-2">
              <div className="col g-0" style={{ margin: "0 15px" }}>
                <div className="row g-0">
                  <table className="table">
                    <colgroup>
                      <col style={{ width: "16%" }} />
                      <col style={{ width: "21%" }} />
                      <col style={{ width: "16%" }} />
                      <col style={{ width: "16%" }} />
                      <col style={{ width: "21%" }} />
                    </colgroup>
                    <thead className="table-head">
                      <tr>
                        <th
                          style={{ paddingLeft: "23px" }}
                          className="table-th"
                        >
                          Format
                        </th>
                        <th className="table-th">Title</th>
                        <th className="table-th">Date</th>
                        <th className="table-th">Location</th>
                        <th
                          className="table-th"
                          style={{ paddingRight: "23px" }}
                        >
                          Source
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedRows.map((row, index) => (
                        <React.Fragment key={index}>
                          <tr
                            onClick={() => handleRowClick(row)}
                            style={{
                              borderBottom: "1px solid #E5E5E5",
                              cursor: "pointer",
                              paddingLeft: "23px",
                            }}
                          >
                            <td
                              style={{ paddingLeft: "23px" }}
                              className="ellipsis"
                            >
                              {row.coll_type}
                            </td>
                            <td className="ellipsis2">
                              <div className="col g-0">
                                <div className="row g-0 title-text">
                                  {row.coll_name}
                                </div>
                              </div>
                            </td>
                            <td>
                              <img
                                src={calendar}
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "5px",
                                }}
                                alt="Calendar Icon"
                              />
                              {row.coll_type === "book"
                                ? row.date_of_publication
                                : row.date_of_journal}
                            </td>
                            <td className="ellipsis">
                              <img
                                src={compass}
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "5px",
                                }}
                                alt="Compass Icon"
                              />
                              {row.coll_location}
                            </td>
                            <td
                              style={{ paddingRight: "23px" }}
                              className="ellipsis"
                            >
                              <img
                                src={multiple}
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "5px",
                                }}
                                alt="Multiple Icon"
                              />
                              {row.coll_source}
                            </td>
                          </tr>
                          {emptyRowsCount > 0 &&
                            index === displayedRows.length - 1 && (
                              <tr style={{ height: 65 * emptyRowsCount }}>
                                <td colSpan={5} />
                              </tr>
                            )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                <PdfViewerModal
                  className="pdf-viewer-modal"
                  show={modalOpen}
                  handleClose={handleCloseModal}
                  searchQuery={""}
                  row={selectedRow}
                />
                <div className="row mt-5 mb-5 g-0">
                  <div className="d-flex justify-content-center">
                    <PageNumbers
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                      PAGINATION_RANGE={PAGINATION_RANGE}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {isNewUser !== null && isNewUser && (
          <FeedbackPopup
            show={showFeedbackPopup}
            handleClose={handleCloseFeedbackPopup}
            fingerprint={fingerprint}
          />
        )}
        <Footer bgcolor="#FFFFFF" />
      </Loading>
    </>
  );
};

export default BrowseResultsPeriodicals;
