import React from "react";
import {
  BarChart,
  ComposedChart,
  Line,
  Brush,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import { ReactComponent as Easel } from "../assets/icons/easel.svg";
import { Modal } from "react-bootstrap";
import theme from "../styles/theme";

import "../styles/analytics.css";

import useAnalytics from "../custom-hooks/useAnalytics";

const Analytics = ({ show, handleClose }) => {
  const {
    analyticsPerCollectionData,
    analyticsPerYearData,
    activeGraph,
    handleButtonClick,
    getPng,
    chartRef,
    handleChartDownload,
    handleResultsDownload,
  } = useAnalytics();

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="my-modal">
      <div className="modal-header-style">
        <div className="modal-flex-box">
          <Easel
            alt="easel"
            style={{
              width: "2rem",
              fill: theme.colors.mainRed,
              transform: "scale(1.2)",
            }}
          />
          <h4 className="modal-title-style">Analytics</h4>
          <button
            type="button"
            className="close modal-close-style"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <hr className="line-style"></hr>
      </div>
      <Modal.Body>
        <div style={{ padding: "1rem", fontFamily: "Roboto" }}>
          <div className="col">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="heading-style"> Number of Matches: </div>
              <button
                className={
                  activeGraph === "collection"
                    ? "active-button"
                    : "inactive-button"
                }
                onClick={() => handleButtonClick("collection")}
              >
                per Collection
              </button>
              <button
                className={
                  activeGraph === "year"
                    ? "active-button"
                    : "inactive-button-left"
                }
                onClick={() => handleButtonClick("year")}
              >
                per Year
              </button>
            </div>
          </div>

          <div className="graph-div-style">
            {activeGraph === "collection" && (
              <BarChart
                ref={chartRef}
                width={825}
                height={450}
                margin={{
                  top: 50,
                  right: 50,
                  bottom: 120,
                  left: 50,
                }}
                data={analyticsPerCollectionData}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="key"
                  padding={{ left: 30, right: 30 }}
                  label={{ value: "Collections", dy: 120 }}
                  className="label-style"
                  angle={-45}
                  interval={0}
                  textAnchor="end"
                  tickMargin={5}
                  tick={{
                    fontSize: analyticsPerCollectionData?.length > 12 ? 15 : 20,
                  }}
                />
                <YAxis
                  className="label-style"
                  label={{
                    value: "No. of Matches",
                    position: "insideLeft",
                    angle: -90,
                    dy: 50,
                    offset: -20,
                  }}
                />
                <Tooltip />
                <Bar name="Matches" dataKey="doc_count" fill="#427E93" />
              </BarChart>
            )}
          </div>

          <div className="graph-div-style">
            {activeGraph === "year" && (
              <ComposedChart
                width={825}
                height={425}
                margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                data={analyticsPerYearData}
                ref={chartRef}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="key_as_string"
                  padding={{ left: 30, right: 30 }}
                  label={{ value: "Years", dy: 70 }}
                  className="label-style"
                />
                <YAxis
                  label={{
                    value: "No. of Matches",
                    position: "insideLeft",
                    angle: -90,
                    offset: -10,
                    dy: 50,
                  }}
                  className="label-style"
                />
                <Tooltip />
                <Bar
                  name="Matches"
                  dataKey="doc_count"
                  barSize={50}
                  fill="#427E93"
                />
                <Line />
                <Brush />
              </ComposedChart>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <button
            className="active-button"
            style={{ marginRight: "25px" }}
            onClick={handleChartDownload}
          >
            Download Chart
          </button>
          <button className="active-button" onClick={handleResultsDownload}>
            Download Results
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Analytics;
