import React, { useEffect, useState } from "react";

import Checkbox from "react-custom-checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Dash } from "../../assets/icons/dash.svg";

import theme from "../../styles/theme";

import "../../styles-arabic/analytics.rtl.css";
import "../../styles-arabic/browse.rtl.css";

import { toArabicNumber } from "../../utils/arabicConversions";

import GenericModal from "../GenericModal";
import useBrowseFiltersBooks from "../../custom-hooks/useBrowseFiltersBooks";

const BrowseFiltersBooksArabic = ({
  setBrowseResults,
  setCurrentPage,
  setLoading,
}) => {
  const {
    authors,
    titles,
    locations,
    publishers,
    volumes,
    selectedAuthors,
    selectedTitles,
    selectedLocations,
    selectedPublishers,
    selectedVolumes,
    selectedKeys1,
    selectedKeys2,
    selectedKeys3,
    selectedKeys4,
    selectedKeys5,
    unselectedKeys1,
    unselectedKeys2,
    unselectedKeys3,
    unselectedKeys4,
    unselectedKeys5,
    maxRowsToShow,
    showMoreAuthor,
    showMoreTitle,
    showMoreLocation,
    showMorePublisher,
    showMoreVolume,
    fromYear,
    toYear,
    minYear,
    maxYear,
    handleAuthorCheckboxChange,
    handleTitleCheckboxChange,
    handleLocationCheckboxChange,
    handlePublisherCheckboxChange,
    handleVolumeCheckboxChange,
    handleFromYearChange,
    handleToYearChange,
    handleCloseAuthorModal,
    handleCloseTitleModal,
    handleCloseLocationModal,
    handleClosePublisherModal,
    handleCloseVolumeModal,
    handleApply,
    clearFilters,
    setShowMoreAuthor,
    setShowMoreLocation,
    setShowMorePublisher,
    setShowMoreTitle,
  } = useBrowseFiltersBooks({ setBrowseResults, setCurrentPage, setLoading });

  return (
    <div style={{ background: theme.colors.mainGrayBackground, width: "100%" }}>
      <div className="row g-0">
        <div className="col g-0">
          <div
            className="heading"
            style={{
              padding: "3rem 0",
              justifyContent: "center",
            }}
          >
            <h2
              className="browse-header"
              style={{
                fontFamily: "Roboto-Slab",
                fontSize: "34px",
              }}
            >
              تصفح مجموعاتنا
            </h2>
          </div>
        </div>
      </div>
      <div class="row" dir="rtl">
        {/*--------AUTHOR--------------------*/}
        <div class="col-sm new-heading">
          <div>اسم الكاتب</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected collections first */}
            {selectedKeys5.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedAuthors[key] || false}
                  onChange={(e) => handleAuthorCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected collections if there are remaining slots */}
            {selectedKeys5.length < maxRowsToShow &&
              unselectedKeys5
                .slice(0, maxRowsToShow - selectedKeys5.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedAuthors[key] || false}
                      onChange={(e) => handleAuthorCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys5.length > maxRowsToShow - selectedKeys5.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreAuthor(true);
                }}
              >
                {showMoreAuthor ? "أقل..." : "المزيد..."}
              </span>
            )}

            <GenericModal
              title="المؤلفون"
              show={showMoreAuthor}
              onHide={handleCloseAuthorModal}
              data={authors}
              selectedData={selectedAuthors}
              handleItemCheckboxChange={handleAuthorCheckboxChange}
            />
          </div>
        </div>
        {/* ------------------TITLE-------------------- */}
        <div class="col-sm new-heading">
          <div>العنوان</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected titles first */}
            {selectedKeys1.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedTitles[key] || false}
                  onChange={(e) => handleTitleCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected titles if there are remaining slots */}
            {selectedKeys1.length < maxRowsToShow &&
              unselectedKeys1
                .slice(0, maxRowsToShow - selectedKeys1.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedTitles[key] || false}
                      onChange={(e) => handleTitleCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys1.length > maxRowsToShow - selectedKeys1.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreTitle(true);
                }}
              >
                {showMoreTitle ? "أقل..." : "المزيد..."}
              </span>
            )}

            <GenericModal
              title="العناوين"
              show={showMoreTitle}
              onHide={handleCloseTitleModal}
              data={titles}
              selectedData={selectedTitles}
              handleItemCheckboxChange={handleTitleCheckboxChange}
            />
          </div>
        </div>

        {/* ------------------PLCAE OF PUBLICATION-------------------- */}
        <div class="col-sm new-heading">
          <div>مكان النشر</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected collections first */}
            {selectedKeys2.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedLocations[key] || false}
                  onChange={(e) => handleLocationCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected collections if there are remaining slots */}
            {selectedKeys2.length < maxRowsToShow &&
              unselectedKeys2
                .slice(0, maxRowsToShow - selectedKeys2.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedLocations[key] || false}
                      onChange={(e) => handleLocationCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys2.length > maxRowsToShow - selectedKeys2.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreLocation(true);
                }}
              >
                {showMoreLocation ? "أقل..." : "المزيد..."}
              </span>
            )}

            <GenericModal
              title="المواقع"
              show={showMoreLocation}
              onHide={handleCloseLocationModal}
              data={locations}
              selectedData={selectedLocations}
              handleItemCheckboxChange={handleLocationCheckboxChange}
            />
          </div>
        </div>

        {/* ------------------PUBLISHER-------------------- */}
        <div class="col-sm new-heading">
          <div>الناشر</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected publishers first */}
            {selectedKeys4.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedPublishers[key] || false}
                  onChange={(e) => handlePublisherCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected collections if there are remaining slots */}
            {selectedKeys4.length < maxRowsToShow &&
              unselectedKeys4
                .slice(0, maxRowsToShow - selectedKeys4.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedPublishers[key] || false}
                      onChange={(e) => handlePublisherCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys4.length > maxRowsToShow - selectedKeys4.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMorePublisher(true);
                }}
              >
                {showMorePublisher ? "أقل..." : "المزيد..."}
              </span>
            )}

            <GenericModal
              title="الناشرون"
              show={showMorePublisher}
              onHide={handleClosePublisherModal}
              data={publishers}
              selectedData={selectedPublishers}
              handleItemCheckboxChange={handlePublisherCheckboxChange}
            />
          </div>
        </div>

        {/* ------------------DATE-------------------- */}
        <div class="col-sm new-heading" style={{ paddingBottom: "45px" }}>
          <div style={{ paddingBottom: "20px" }}>تاريخ النشر</div>
          <div
            className="date-input"
            style={{ fontSize: "1rem", color: "#212529" }}
          >
            <input
              type="text"
              placeholder={
                minYear === "" ? "YYYY" : toArabicNumber(minYear.toString())
              }
              onChange={handleFromYearChange}
              value={fromYear}
              style={{ width: "3em", outline: "none" }}
            />
            <Dash />
            <input
              type="text"
              placeholder={
                maxYear === "" ? "YYYY" : toArabicNumber(maxYear.toString())
              }
              onChange={handleToYearChange}
              value={toYear}
              style={{ width: "3em", outline: "none" }}
            />
          </div>
        </div>
      </div>
      <div className="row g-0" dir="rtl">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "1rem",
          }}
        >
          <button
            className="btn rounded-0 apply-button"
            style={{
              margin: "3px 3px",
              width: "70px",
              height: "35px",
            }}
            onClick={handleApply}
          >
            <span>تطبيق</span>
          </button>
          <button
            className="btn rounded-0 clear-button"
            style={{
              margin: "3px 3px",
              width: "70px",
              height: "35px",
            }}
            onClick={clearFilters}
          >
            مسح
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrowseFiltersBooksArabic;
