import React from "react";
import { toArabicNumber } from "../../utils/arabicConversions";

const PageNumbersArabic = ({
  currentPage,
  totalPages,
  handlePageChange,
  PAGINATION_RANGE = 2,
}) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];

    let startPage = Math.max(1, currentPage - PAGINATION_RANGE);
    let endPage = Math.min(totalPages, currentPage + PAGINATION_RANGE);

    const showEllipsisRight = currentPage - PAGINATION_RANGE > 2;
    const showEllipsisLeft = currentPage + PAGINATION_RANGE < totalPages - 1;

    if (currentPage < totalPages) {
      pageNumbers.push(
        <button
          className="pagination-button"
          style={{
            backgroundColor: "#CC0000",
            color: "white",
            fontWeight: "900",
          }}
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          ←
        </button>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <button
          className="pagination-button"
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {toArabicNumber(totalPages.toString())}
        </button>
      );
    }

    if (showEllipsisLeft) {
      pageNumbers.push(
        <span key="ellipsis-left" style={{ margin: "0px 24px" }}>
          ...
        </span>
      );
    }

    for (let page = endPage; page >= startPage; page--) {
      pageNumbers.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className="pagination-button"
          style={
            currentPage === page ? { borderBottom: "1px solid #CC0000" } : {}
          }
        >
          {toArabicNumber(page.toString())}
        </button>
      );
    }

    if (showEllipsisRight) {
      pageNumbers.push(
        <span key="ellipsis-right" style={{ margin: "0px 24px" }}>
          ...
        </span>
      );
    }

    if (startPage > 1) {
      pageNumbers.push(
        <button
          className="pagination-button"
          key={1}
          onClick={() => handlePageChange(1)}
        >
          {toArabicNumber("1")}
        </button>
      );
    }

    if (currentPage > 1) {
      pageNumbers.push(
        <button
          key="prev"
          style={{
            backgroundColor: "#CC0000",
            color: "white",
            fontWeight: "900",
          }}
          className="pagination-button"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          →
        </button>
      );
    }

    return pageNumbers;
  };

  return <div className="pagination-container">{renderPageNumbers()}</div>;
};

export default PageNumbersArabic;
