import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useFeedbackMonitor from "../custom-hooks/useFeedbackMonitor";

const ITEMS_PER_PAGE = 10;

const useBrowseResults = () => {
  const location = useLocation();

  const [browseResults, setBrowseResults] = useState(
    location.state?.browseResults || []
  );
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [analyticsModalOpen, setAnalyticsModalOpen] = useState(false);

  const totalPages = Math.ceil(browseResults.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const displayedRows = browseResults.slice(startIndex, endIndex);
  const emptyRowsCount = ITEMS_PER_PAGE - displayedRows.length;

  const {
    showFeedbackPopup,
    handleCloseFeedbackPopup,
    fingerprint,
    isNewUser,
  } = useFeedbackMonitor();

  useEffect(() => {
    setCurrentPage(1);
  }, [location.state?.browseResults]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseAnalyticsModal = () => {
    setAnalyticsModalOpen(false);
  };

  const countAttributes = (attribute) => {
    const attributeCounts = [];
    browseResults.forEach((result) => {
      const value = result[attribute];
      const index = attributeCounts.findIndex(
        (obj) => obj.attribute === attribute && obj.value === value
      );
      if (index === -1) {
        attributeCounts.push({ attribute, value, count: 1 });
      } else {
        attributeCounts[index].count += 1;
      }
    });
    return attributeCounts;
  };

  const collTypeCounts = countAttributes("coll_type");
  const collSourceCounts = countAttributes("coll_source");
  const collLocationCounts = countAttributes("coll_location");

  const collNameIdentifier = browseResults ? countAttributes("coll_name") : [];

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.setItem("browseItemsSet", false);
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return {
    browseResults,
    setBrowseResults,
    loading,
    setLoading,
    currentPage,
    setCurrentPage,
    totalPages,
    displayedRows,
    emptyRowsCount,
    handlePageChange,
    modalOpen,
    setModalOpen,
    selectedRow,
    setSelectedRow,
    handleRowClick,
    handleCloseModal,
    analyticsModalOpen,
    setAnalyticsModalOpen,
    handleCloseAnalyticsModal,
    showFeedbackPopup,
    handleCloseFeedbackPopup,
    fingerprint,
    isNewUser,
    collTypeCounts,
    collSourceCounts,
    collLocationCounts,
    collNameIdentifier,
  };
};

export default useBrowseResults;
