export const isArabicNumber = (strInput) => {
  const arnumregex = /[\u0660-\u0669]/;
  return arnumregex.test(strInput);
};

export const toArabicNumber = (strNum) => {
  const ar = "٠١٢٣٤٥٦٧٨٩".split("");
  const en = "0123456789".split("");
  // strNum = strNum.replace(/[0-9]/g, (match) => ar[en.indexOf(match)]);
  // return strNum;
  return strNum
    .split("")
    .reverse()
    .map((digit) => ar[en.indexOf(digit)])
    .join("");
};

export const toArabicNumberHomepage = (number) => {
  const arabicNumbers =
    "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669";
  let arabicNumber = "";
  for (const digit of number.toString().split("")) {
    arabicNumber += arabicNumbers[parseInt(digit)];
  }
  return arabicNumber;
};

export const convertToArabicDate = (englishDate) => {
  const [year, month, day] = englishDate?.split("-");

  const arabicYear = isArabicNumber(year) ? year : toArabicNumber(year);
  const arabicMonth = isArabicNumber(month) ? month : toArabicNumber(month);
  const arabicDay = isArabicNumber(day) ? day : toArabicNumber(day);

  return `${arabicYear}/${arabicMonth}/${arabicDay}`;
};

export const toEnglishNumber = (strNum) => {
  var ar = "٠١٢٣٤٥٦٧٨٩".split("");
  var en = "0123456789".split("");
  strNum = strNum.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x) => en[ar.indexOf(x)]);
  strNum = strNum.replace(/[^\d]/g, "");
  return strNum;
};

export const convertArabicToNumber = (arabicNumeral) => {
  const arabicToNumberMapping = {
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };
  let number = "";
  for (let i = 0; i < arabicNumeral.length; i++) {
    number += arabicToNumberMapping[arabicNumeral[i]] || arabicNumeral[i];
  }
  return parseInt(number);
};
