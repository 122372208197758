import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Loading from "./Loading";
import BrowseFiltersBooks from "./BrowseFiltersBooks";
import FeedbackPopup from "./FeedbackPopup";
import theme from "../styles/theme";
import PdfViewerModal from "./PdfViewerModal";
import calendar from "../assets/icons/calendar.svg";
import compass from "../assets/icons/compass.svg";
import "../styles/search-results.css";
import PageNumbers from "./PageNumbers";
import useBrowseResults from "../custom-hooks/useBrowseResults";

const PAGINATION_RANGE = 2;

const BrowseResultsBooks = () => {
  const {
    browseResults,
    setBrowseResults,
    loading,
    setLoading,
    currentPage,
    setCurrentPage,
    totalPages,
    displayedRows,
    handlePageChange,
    modalOpen,
    selectedRow,
    handleRowClick,
    handleCloseModal,
    showFeedbackPopup,
    handleCloseFeedbackPopup,
    fingerprint,
    isNewUser,
  } = useBrowseResults();

  return (
    <>
      <Loading isLoading={loading}>
        <div
          style={{ background: theme.colors.mainGrayBackground, width: "100%" }}
        >
          <Header />
          <div>
            {/* <SearchInput
              searchString={searchString}
              setSearchResults={setSearchResults}
              setAnalytics={setAnalytics}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
            /> */}
            <BrowseFiltersBooks
              setBrowseResults={setBrowseResults}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
            />
          </div>
          {browseResults && browseResults.length > 0 && (
            <div className="row g-0 mt-4 ml-2 mr-2">
              <div className="col g-0" style={{ margin: "0 15px" }}>
                <div className="row g-0">
                  <table className="table">
                    <colgroup>
                      <col style={{ width: "14%" }} />
                      <col style={{ width: "14%" }} />
                      <col style={{ width: "14%" }} />
                      <col style={{ width: "14%" }} />
                      <col style={{ width: "14%" }} />
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "15%" }} />
                    </colgroup>
                    <thead className="table-head">
                      <tr>
                        <th className="table-th">Title</th>
                        <th className="table-th">Date</th>
                        <th className="table-th">Place of Publication</th>
                        <th className="table-th">Publisher</th>
                        <th className="table-th">Volume</th>
                        <th className="table-th">Language</th>
                        <th className="table-th">Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedRows.map((row, index) => (
                        <React.Fragment key={index}>
                          <tr
                            onClick={() => handleRowClick(row)}
                            style={{
                              borderBottom: "1px solid #E5E5E5",
                              cursor: "pointer",
                            }}
                          >
                            <td className="ellipsis title-text">{row.title}</td>
                            <td>
                              <img
                                src={calendar}
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "5px",
                                }}
                                alt="Calendar Icon"
                              />
                              {row.date_of_publication}
                            </td>
                            <td className="ellipsis">
                              <img
                                src={compass}
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  marginRight: "5px",
                                }}
                                alt="Compass Icon"
                              />
                              {row.place_of_publication}
                            </td>
                            <td className="ellipsis">{row.publisher}</td>
                            <td>{row.volume}</td>
                            <td>{row.language}</td>
                            <td className="ellipsis">{row.authors}</td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                <PdfViewerModal
                  className="pdf-viewer-modal"
                  show={modalOpen}
                  handleClose={handleCloseModal}
                  searchQuery={""}
                  row={selectedRow}
                />
                <div className="row mt-5 mb-5 g-0">
                  <div className="d-flex justify-content-center">
                    <PageNumbers
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                      PAGINATION_RANGE={PAGINATION_RANGE}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {isNewUser !== null && isNewUser && (
          <FeedbackPopup
            show={showFeedbackPopup}
            handleClose={handleCloseFeedbackPopup}
            fingerprint={fingerprint}
          />
        )}
        <Footer bgcolor="#FFFFFF" />
      </Loading>
    </>
  );
};

export default BrowseResultsBooks;
