import React from "react";
import Header from "./Header";
import SearchInput from "./SearchInput";
import Footer from "./Footer";
import Loading from "./Loading";
import theme from "../styles/theme";
import PdfViewerModal from "./PdfViewerModal";
import Modal from "react-bootstrap/Modal";
import calendar from "../assets/icons/calendar.svg";
import compass from "../assets/icons/compass.svg";
import multiple from "../assets/icons/multiple-documents.svg";
import easel from "../assets/icons/easel.svg";
import LeftPane from "./LeftPane";
import FeedbackPopup from "./FeedbackPopup";
import Analytics from "./Analytics";
import PageNumbers from "./PageNumbers";
import useSearchResults from "../custom-hooks/useSearchResults";

const PAGINATION_RANGE = 2;

const SearchResults = () => {
  const {
    searchString,
    setSearchResults,
    setSearchResultSets,
    setAnalytics,
    currentPage,
    setCurrentPage,
    loading,
    setLoading,
    collTypeCounts,
    collNameIdentifier,
    collSourceCounts,
    collLocationCounts,
    displayedRows,
    emptyRowsCount,
    totalPages,
    handlePageChange,
    handleShow,
    handleClose,
    show,
    handleRowClick,
    selectedRow,
    modalOpen,
    handleCloseModal,
    handleAnalyticsClick,
    analyticsModalOpen,
    handleCloseAnalyticsModal,
    handleHeadingClick,
    sortArrowElement,
    isNewUser,
    fingerprint,
    showFeedbackPopup,
    handleCloseFeedbackPopup,
    setShow,
    setAnalyticsModalOpen,
    visitedRows,
  } = useSearchResults();

  return (
    <>
      <Loading isLoading={loading}>
        <div
          style={{ background: theme.colors.mainGrayBackground, width: "100%" }}
        >
          <Header />
          <div>
            <SearchInput
              searchString={searchString}
              setSearchResults={setSearchResults}
              setSearchResultSets={setSearchResultSets}
              setAnalytics={setAnalytics}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
            />
          </div>

          <br></br>
          <div className="filters">
            <div
              className="row g-0"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button className="filter-button" onClick={handleShow}>
                <b>Filters</b>
              </button>
            </div>

            <button className="analytics-button" onClick={handleAnalyticsClick}>
              <img
                style={{
                  width: "20px",
                  height: "20px",
                }}
                alt=""
                src={easel}
              />
              <b>Analytics</b>
            </button>
            <Analytics
              show={analyticsModalOpen}
              handleClose={handleCloseAnalyticsModal}
            />
          </div>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton dir="ltr">
              <Modal.Title className="filter-modal-title">Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <div className="row g-0 mt-4 ml-2 left-pane-modal">
                <LeftPane
                  btn={false}
                  setShow={setShow}
                  searchString={searchString}
                  setSearchResults={setSearchResults}
                  setSearchResultSets={setSearchResultSets}
                  setAnalytics={setAnalytics}
                  setCurrentPage={setCurrentPage}
                  collTypeCounts={collTypeCounts}
                  collNameIdentifier={collNameIdentifier}
                  collSourceCounts={collSourceCounts}
                  collLocationCounts={collLocationCounts}
                  setAnalyticsModalOpen={setAnalyticsModalOpen}
                  setLoading={setLoading}
                />
              </div>
            </Modal.Body>
          </Modal>

          <div className="row g-0 mt-4 ml-2">
            <div
              className="left-pane col-3 g-0"
              style={{ marginLeft: "60px", maxWidth: "20%" }}
            >
              <LeftPane
                btn={true}
                searchString={searchString}
                setSearchResults={setSearchResults}
                setSearchResultSets={setSearchResultSets}
                setAnalytics={setAnalytics}
                setCurrentPage={setCurrentPage}
                collTypeCounts={collTypeCounts}
                collNameIdentifier={collNameIdentifier}
                collSourceCounts={collSourceCounts}
                collLocationCounts={collLocationCounts}
                setAnalyticsModalOpen={setAnalyticsModalOpen}
                setLoading={setLoading}
              />
            </div>
            <div
              className="col-8 g-0"
              style={{ marginLeft: "15px", width: "60vw" }}
            >
              <div className="row g-0">
                <table className="table">
                  <colgroup>
                    <col style={{ width: "16%" }} />
                    <col style={{ width: "21%" }} />
                    <col style={{ width: "16%" }} />
                    <col style={{ width: "16%" }} />
                    <col style={{ width: "21%" }} />
                  </colgroup>
                  <thead className="table-head">
                    <tr>
                      <th
                        style={{ paddingLeft: "23px" }}
                        className="table-th-2"
                      >
                        Format
                      </th>
                      <th
                        className="table-th-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleHeadingClick("coll_name")}
                      >
                        Title
                        <span>{sortArrowElement("coll_name")}</span>
                      </th>
                      <th
                        className="table-th-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleHeadingClick("date")}
                      >
                        Date
                        <span>{sortArrowElement("date")}</span>
                      </th>
                      <th
                        className="table-th-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleHeadingClick("coll_location")}
                      >
                        Location
                        <span>{sortArrowElement("coll_location")}</span>
                      </th>
                      <th
                        className="table-th-2"
                        style={{ paddingRight: "23px", cursor: "pointer" }}
                        onClick={() => handleHeadingClick("coll_source")}
                      >
                        Source
                        <span>{sortArrowElement("coll_source")}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedRows.map((row, index) => (
                      <React.Fragment key={index}>
                        <tr
                          onClick={() => handleRowClick(row)}
                          style={{
                            borderBottom: "1px solid #E5E5E5",
                            cursor: "pointer",
                            paddingLeft: "23px",
                          }}
                        >
                          <td
                            style={{ paddingLeft: "23px" }}
                            className="ellipsis"
                          >
                            {row.coll_type}
                          </td>
                          <td className="ellipsis2">
                            <div className="col g-0">
                              <div className="row g-0">
                                Page {row.page_number} of
                              </div>
                              <div
                                className={`row g-0 title-text ${visitedRows.has(row.AWS_link)
                                  ? "visited-row"
                                  : ""
                                  }`}
                              >
                                {row.coll_name}
                              </div>
                            </div>
                          </td>
                          <td className="ellipsis2">
                            <img
                              src={calendar}
                              style={{
                                width: "16px",
                                height: "16px",
                                marginRight: "5px",
                              }}
                              alt="Calendar Icon"
                            />
                            {row.date}
                          </td>
                          <td className="ellipsis">
                            <img
                              src={compass}
                              style={{
                                width: "16px",
                                height: "16px",
                                marginRight: "5px",
                              }}
                              alt="Compass Icon"
                            />
                            {row.coll_location}
                          </td>
                          <td
                            style={{ paddingRight: "2px" }}
                            className="ellipsis"
                          >
                            <img
                              src={multiple}
                              style={{
                                width: "16px",
                                height: "16px",
                                marginRight: "5px",
                              }}
                              alt="Multiple Icon"
                            />
                            {row.coll_source}
                          </td>
                        </tr>
                        {emptyRowsCount > 0 &&
                          index === displayedRows.length - 1 && (
                            <tr style={{ height: 65 * emptyRowsCount }}>
                              <td colSpan={5} />
                            </tr>
                          )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <PdfViewerModal
                className="pdf-viewer-modal"
                show={modalOpen}
                handleClose={handleCloseModal}
                searchQuery={searchString}
                row={selectedRow}
              />
              {/* Analytics Modal */}
              <Analytics
                show={analyticsModalOpen}
                handleClose={handleCloseAnalyticsModal}
              />
              <div className="row mt-5 mb-5 g-0">
                <div className="d-flex justify-content-center">
                  <PageNumbers
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    PAGINATION_RANGE={PAGINATION_RANGE}
                  />
                </div>
              </div>
            </div>
          </div>
          {isNewUser !== null && isNewUser && (
            <FeedbackPopup
              show={showFeedbackPopup}
              handleClose={handleCloseFeedbackPopup}
              fingerprint={fingerprint}
            />
          )}
        </div>
        <Footer bgcolor="#FFFFFF" />
      </Loading>
    </>
  );
};

export default SearchResults;
