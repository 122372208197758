import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

import AlHoda from "../assets/images/Al Hoda.jpg";
import Jurab from "../assets/images/Jurab.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import "../styles/learn-more.css";

import theme from "../styles/theme";

const LearnMore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="background">
        <div className="container g-0" style={{ backgroundColor: "#fff" }}>
          <div>
            <div className="row g-0 heading">
              <div className="col-2 back-button-div">
                <button
                  className="btn rounded-0"
                  onClick={() => window.history.back()}
                  style={{ backgroundColor: theme.colors.mainRed }}
                >
                  <FontAwesomeIcon
                    style={{ color: "#FFFFFF" }}
                    icon={faArrowLeft}
                  />
                  <span style={{ color: "#FFFFFF", marginLeft: "5px" }}>
                    Back
                  </span>
                  &nbsp;&nbsp;
                </button>
              </div>
              <div
                className="col-8 g-0"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h2 className="learn-more-header">About the Project</h2>
              </div>
              <div className="col-2 g-0"></div>
            </div>
            <div className="row g-0" style={{ justifyContent: "center" }}>
              <hr className="line" />
            </div>
          </div>
          <div className="row g-0 about-section" style={{ padding: "15px" }}>
            <div className="col-8 text-col">
              <div className="row">
                <p className="paragraph">
                  Early Arab immigrants in North and South America have left a
                  rich legacy and history. Much of their histories have been
                  recorded in millions of pages of Arabic newspapers, books,
                  magazines, and other publications. Yet, this rich record has
                  been largely inaccessible because it was dispersed, stored in
                  disparate archives, and stored in older technologies like
                  microfilm. Now, The Khayrallah Center’s Arabic Newspaper
                  Database makes these records digitally searchable.
                  <br />
                  <br />
                  The database allows you to search for specific words or
                  phrases, and to filter your search by year, publication,
                  and/or place of publication. You can look for individuals as
                  they leave traces across pages, follow the debates which
                  swirled in early Arab Americans communities, read literary
                  works, examine political and economic news, and enter the
                  remarkably rich world of the{" "}
                  <i style={{ fontWeight: "bold" }}>mahjar</i>.
                </p>
              </div>
            </div>
            <div className="col-4 img-col">
              <img src={AlHoda} className="image" alt="Periodical Image" />
            </div>
          </div>
          <div className="videos" style={{ padding: "40px" }}>
            <div>
              <p
                className="paragraph"
                style={{ color: "#12394d", fontSize: "25px" }}
              >
                Learn more from the development team behind the archive
              </p>
              <div className="iframe-container">
                <iframe
                  src="https://www.youtube.com/embed/woidCJBHwV0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <br></br>
            <br></br>
            <div>
              <p
                className="paragraph"
                style={{ color: "#12394d", fontSize: "25px" }}
              >
                Learn how to use the Arabic Newspaper Database
              </p>
              <div className="iframe-container">
                <iframe
                  src="https://www.youtube.com/embed/eC_j70SZTxw?si=zX530F7j_W-fuVVu"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row g-0 last-section" style={{ padding: "15px" }}>
            <div
              className="col-4 img-col child1"
              style={{ maxHeight: "250px" }}
            >
              <img src={Jurab} className="image" alt="Periodical Image" />
            </div>
            <div className="col-8 text-col child2">
              <div className="row g-0" style={{ padding: "10px" }}>
                <p className="paragraph">
                  Over the coming months we will continue to add to this tool
                  with new capabilities including an Arabic interface and AI,
                  and enrich and expand the database with new publications.
                  <br />
                  <br />
                  We welcome your support in maintaining and growing this free
                  archive. Please consider donating a{" "}
                  <a href="https://lebanesestudies.ncsu.edu/donate/">
                    small gift
                  </a>{" "}
                  to help sustain our efforts. Also, please let us know of any
                  publications that we can add to this archive. You can connect
                  us at{" "}
                  <a href="mailto:lebanesestudies@ncsu.edu">
                    lebanesestudies@ncsu.edu
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer bgcolor="#fff" />
    </>
  );
};

export default LearnMore;
