import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { browseAPI } from "../axios/axios";

import { toast } from "react-toastify";
import useYearInputValidation from "../custom-hooks/useYearInputValidation";

const useBrowseFiltersBooks = ({
  setBrowseResults,
  setCurrentPage,
  setLoading,
}) => {
  const [volumes, setVolumes] = useState([]);
  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");
  const [locations, setLocations] = useState([]);
  const [titles, setTitles] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [authors, setAuthors] = useState([]);

  const [selectedLocations, setSelectedLocations] = useState({});
  const [selectedTitles, setSelectedTitles] = useState({});
  const [selectedPublishers, setSelectedPublishers] = useState({});
  const [selectedAuthors, setSelectedAuthors] = useState({});
  const [selectedVolumes, setSelectedVolumes] = useState({});

  const [showMoreLocation, setShowMoreLocation] = useState(false);
  const [showMoreTitle, setShowMoreTitle] = useState(false);
  const [showMorePublisher, setShowMorePublisher] = useState(false);
  const [showMoreAuthor, setShowMoreAuthor] = useState(false);
  const [showMoreVolume, setShowMoreVolume] = useState(false);

  const handleCloseLocationModal = () => {
    setShowMoreLocation(false);
  };

  const handleCloseTitleModal = () => {
    setShowMoreTitle(false);
  };

  const handleClosePublisherModal = () => {
    setShowMorePublisher(false);
  };

  const handleCloseAuthorModal = () => {
    setShowMoreAuthor(false);
  };

  const handleCloseVolumeModal = () => {
    setShowMoreVolume(false);
  };

  const lang = localStorage.getItem("lang");
  const isArabic = lang === "ar";

  const navigate = useNavigate();
  const location = useLocation();
  const coll_type = "book";
  let params = { coll_type: coll_type };
  const [selectedCollType, setSelectedCollType] = useState("book");

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await browseAPI.post("/metadata/book", params);

        setLocations(
          isArabic
            ? res.data.place_of_publication_arabic
            : res.data.place_of_publication
        );
        setMinYear(res.data.from_year);
        setMaxYear(res.data.to_year);
        setTitles(isArabic ? res.data.title_arabic : res.data.title);
        setPublishers(
          isArabic ? res.data.publisher_arabic : res.data.publisher
        );
        setAuthors(isArabic ? res.data.author_arabic : res.data.author);
        setVolumes(res.data.volume);
        setTimeout(() => {}, 1000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const maxRowsToShow = 3;

  const {
    year: fromYear,
    isValid: isFromYearValid,
    handleYearChange: handleFromYearChange,
  } = useYearInputValidation(minYear, maxYear);
  const {
    year: toYear,
    isValid: isToYearValid,
    handleYearChange: handleToYearChange,
  } = useYearInputValidation(minYear, maxYear);

  // Separate the selected and unselected volumes
  const tempSelectedKeys3 = Object.keys(selectedVolumes);
  const selectedKeys3 = tempSelectedKeys3.map((key) => ({
    key,
    value: volumes[key],
  }));
  const unselectedKeys3 = Object.entries(volumes)
    .filter(([key]) => !selectedVolumes[key])
    .sort((a, b) => {
      let aValue = a ? a.toString() : "";
      let bValue = b ? b.toString() : "";
      return aValue.localeCompare(bValue);
    });

  // Separate the selected and unselected locations
  const tempSelectedKeys2 = Object.keys(selectedLocations);
  const selectedKeys2 = tempSelectedKeys2.map((key) => ({
    key,
    value: locations[key],
  }));
  const unselectedKeys2 = Object.entries(locations)
    .filter(([key]) => !selectedLocations[key])
    .sort((a, b) => a[1].localeCompare(b[1]));

  // Separate the selected and unselected titles
  const tempSelectedKeys1 = Object.keys(selectedTitles);
  const selectedKeys1 = tempSelectedKeys1.map((key) => ({
    key,
    value: titles[key],
  }));
  const unselectedKeys1 = Object.entries(titles)
    .filter(([key]) => !selectedTitles[key])
    .sort((a, b) => a[1].localeCompare(b[1]));

  // Separate the selected and unselected publishers
  const tempSelectedKeys4 = Object.keys(selectedPublishers);
  const selectedKeys4 = tempSelectedKeys4.map((key) => ({
    key,
    value: publishers[key],
  }));
  const unselectedKeys4 = Object.entries(publishers)
    .filter(([key]) => !selectedPublishers[key])
    .sort((a, b) => a[1].localeCompare(b[1]));

  // Separate the selected and unselected authors
  const tempSelectedKeys5 = Object.keys(selectedAuthors);
  const selectedKeys5 = tempSelectedKeys5.map((key) => ({
    key,
    value: authors[key],
  }));
  const unselectedKeys5 = Object.entries(authors)
    .filter(([key]) => !selectedAuthors[key])
    .sort((a, b) => a[1].localeCompare(b[1]));

  const handleLocationCheckboxChange = (event, index) => {
    setSelectedLocations((prevSelectedLocations) => {
      const updatedSelectedLocations = { ...prevSelectedLocations };
      if (event) {
        updatedSelectedLocations[index] = true;
      } else {
        delete updatedSelectedLocations[index];
      }
      return updatedSelectedLocations;
    });
  };

  const handleTitleCheckboxChange = (event, index) => {
    setSelectedTitles((prevSelectedTitles) => {
      const updatedSelectedTitles = { ...prevSelectedTitles };
      if (event) {
        updatedSelectedTitles[index] = true;
      } else {
        delete updatedSelectedTitles[index];
      }
      return updatedSelectedTitles;
    });
  };

  const handlePublisherCheckboxChange = (event, index) => {
    setSelectedPublishers((prevSelectedPublishers) => {
      const updatedSelectedPublishers = { ...prevSelectedPublishers };
      if (event) {
        updatedSelectedPublishers[index] = true;
      } else {
        delete updatedSelectedPublishers[index];
      }
      return updatedSelectedPublishers;
    });
  };

  const handleAuthorCheckboxChange = (event, index) => {
    setSelectedAuthors((prevSelectedAuthors) => {
      const updatedSelectedAuthors = { ...prevSelectedAuthors };
      if (event) {
        updatedSelectedAuthors[index] = true;
      } else {
        delete updatedSelectedAuthors[index];
      }
      return updatedSelectedAuthors;
    });
  };

  const handleVolumeCheckboxChange = (event, index) => {
    setSelectedVolumes((prevSelectedVolumes) => {
      const updatedSelectedVolumes = { ...prevSelectedVolumes };
      if (event) {
        updatedSelectedVolumes[index] = true;
      } else {
        delete updatedSelectedVolumes[index];
      }
      return updatedSelectedVolumes;
    });
  };

  const handleApply = async () => {
    if (parseInt(fromYear) > parseInt(toYear)) {
      toast.error(
        isArabic
          ? "الرجاء إدخال نطاق سنوات صالح"
          : "Please enter a valid range of years",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          rtl: isArabic,
          draggable: true,
        }
      );
      return;
    }

    setLoading(true);
    //Send the params to the backend
    const params = {
      coll_type: selectedCollType,
      place_of_publication: isArabic
        ? undefined
        : getList(locations, selectedKeys2),
      place_of_publication_arabic: isArabic
        ? getList(locations, selectedKeys2)
        : undefined,
      title: isArabic ? undefined : getList(titles, selectedKeys1),
      title_arabic: isArabic ? getList(titles, selectedKeys1) : undefined,
      publisher: isArabic ? undefined : getList(publishers, selectedKeys4),
      publisher_arabic: isArabic
        ? getList(publishers, selectedKeys4)
        : undefined,
      author: isArabic ? undefined : getList(authors, selectedKeys5),
      author_arabic: isArabic ? getList(authors, selectedKeys5) : undefined,
      volume: getList(volumes, selectedKeys3),
      from_year: parseInt(fromYear),
      to_year: parseInt(toYear),
    };

    try {
      let res = await browseAPI.post("/data/book", params);

      if (res.data.length === 0) {
        toast.warn(isArabic ? "لا توجد نتائج" : "No results found", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          rtl: isArabic,
          draggable: true,
        });
      } else {
        // Actions to perform if results are found
        localStorage.setItem("browseItemsSet", JSON.stringify(false));
        setBrowseResults && setBrowseResults(res.data);
        setCurrentPage && setCurrentPage(1);

        navigate("/browse-books", {
          state: {
            ...params,
            browseResults: res.data,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const clearFilters = async () => {
    setLoading(true);

    setSelectedLocations({});
    setSelectedTitles({});
    setSelectedPublishers({});
    setSelectedAuthors({});
    setSelectedVolumes({});
    handleFromYearChange({ target: { value: "" } });
    handleToYearChange({ target: { value: "" } });

    // Sending the params to the backend
    const params = {};

    try {
      let res = await browseAPI.post("/data/book", params);

      if (res.data.length === 0) {
        toast.warn(isArabic ? "لا توجد نتائج" : "No results found", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          rtl: isArabic,
          draggable: true,
        });
      } else {
        localStorage.setItem("browseItemsSet", JSON.stringify(false));
        setBrowseResults && setBrowseResults(res.data);
        setCurrentPage && setCurrentPage(1);

        navigate("/browse-books", {
          state: {
            coll_type: coll_type,
            place_of_publication: getList(locations, selectedKeys2),
            title: getList(titles, selectedKeys1),
            publisher: getList(publishers, selectedKeys4),
            volume: getList(volumes, selectedKeys3),
            author: getList(authors, selectedKeys5),
            from_year: parseInt(fromYear),
            to_year: parseInt(toYear),
            browseResults: res.data,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getList = (dictionary, selectedKeys) => {
    var list = [];
    selectedKeys.forEach(({ key, value }) => list.push(dictionary[key]));
    return list;
  };

  return {
    locations,
    titles,
    publishers,
    authors,
    volumes,
    selectedLocations,
    selectedTitles,
    selectedPublishers,
    selectedAuthors,
    selectedVolumes,
    showMoreLocation,
    showMoreTitle,
    showMorePublisher,
    showMoreAuthor,
    showMoreVolume,
    maxRowsToShow,
    fromYear,
    toYear,
    isFromYearValid,
    isToYearValid,
    handleFromYearChange,
    handleToYearChange,
    handleLocationCheckboxChange,
    handleTitleCheckboxChange,
    handlePublisherCheckboxChange,
    handleAuthorCheckboxChange,
    handleVolumeCheckboxChange,
    handleCloseLocationModal,
    handleCloseTitleModal,
    handleClosePublisherModal,
    handleCloseAuthorModal,
    handleCloseVolumeModal,
    handleApply,
    clearFilters,
    unselectedKeys2,
    unselectedKeys1,
    unselectedKeys4,
    unselectedKeys5,
    unselectedKeys3,
    selectedKeys2,
    selectedKeys1,
    selectedKeys4,
    selectedKeys5,
    selectedKeys3,
    setShowMoreAuthor,
    setShowMoreLocation,
    setShowMorePublisher,
    setShowMoreTitle,
    minYear,
    maxYear,
  };
};

export default useBrowseFiltersBooks;
