// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  cursor: pointer;
}

.corner-button-style {
  display: flex;
  justify-content: left;
  padding-left: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.arabic-corner-button {
  border-width: 2px;
  /* margin: 1rem; */
}

.nav-items {
  display: flex;
}

.items {
  display: flex;
}

.arabic-give-button {
  margin: 6px;
  margin-right: 1rem;
}

.arabic-new-button {
  margin: 5px;
}

@media (max-width: 850px) {
  .nav-items {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    margin: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles-arabic/header.rtl.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,SAAO;IACP,8BAA8B;IAC9B,YAAY;EACd;AACF","sourcesContent":[".logo {\n  display: flex;\n  justify-content: center;\n  margin-top: 32px;\n  cursor: pointer;\n}\n\n.corner-button-style {\n  display: flex;\n  justify-content: left;\n  padding-left: 40px;\n  margin-top: 40px;\n  margin-bottom: 40px;\n}\n\n.arabic-corner-button {\n  border-width: 2px;\n  /* margin: 1rem; */\n}\n\n.nav-items {\n  display: flex;\n}\n\n.items {\n  display: flex;\n}\n\n.arabic-give-button {\n  margin: 6px;\n  margin-right: 1rem;\n}\n\n.arabic-new-button {\n  margin: 5px;\n}\n\n@media (max-width: 850px) {\n  .nav-items {\n    display: flex;\n    flex: 1;\n    justify-content: space-between;\n    margin: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
