import React, { useState, useCallback } from "react";

import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";

const useAnalytics = () => {
  const analyticsData = JSON.parse(localStorage.getItem("analytics"));
  const analyticsPerCollectionData =
    analyticsData?.analytics_per_collection?.buckets;
  const analyticsPerYearData = analyticsData?.analytics_per_year?.buckets;
  const [activeGraph, setActiveGraph] = useState("collection");

  const handleButtonClick = (graph) => {
    setActiveGraph(graph);
  };

  // Also accepts an optional argument for Html2Canvas options: useRechartToPng(options)
  const [getPng, { ref: chartRef }] = useCurrentPng();

  const handleChartDownload = useCallback(async () => {
    // Get the current png
    const png = await getPng();
    // Verify that png is not undefined
    if (png) {
      // Download with FileSaver
      FileSaver.saveAs(png, "myChart.png");
    }
  }, [getPng]);

  const handleResultsDownload = useCallback(() => {
    const results = JSON.parse(localStorage.getItem("searchResults"));
    if (results) {
      const serializedData = results
        .map((obj) => JSON.stringify(obj) + ",\n")
        .join("");
      const resArr = [serializedData];
      var blob = new Blob(resArr, { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, "results.json");
    }
  });
  return {
    analyticsPerCollectionData,
    analyticsPerYearData,
    activeGraph,
    handleButtonClick,
    getPng,
    chartRef,
    handleChartDownload,
    handleResultsDownload,
  };
};

export default useAnalytics;
