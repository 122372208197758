import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { Row } from "reactstrap";
import calendar from "../assets/icons/calendar.svg";
import compass from "../assets/icons/compass.svg";
import multiple from "../assets/icons/multiple-documents.svg";

import RenderResultPDF from "./old/ResultPDFComponentBrowse";

const PdfViewer = () => {
  const location = useLocation();
  const pdfUrl = location.state ? location.state.pdfUrl : null;
  const row = location.state ? location.state.row : null;

  useState(() => {}, [pdfUrl]);

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col">{row.coll_name}</div>
          <div className="col">
            <img
              src={calendar}
              style={{
                width: "16px",
                height: "16px",
                marginRight: "5px",
              }}
              alt="Calendar Icon"
            />
            {row.date}
          </div>
          <div className="col">
            <img
              src={compass}
              style={{
                width: "27px",
                height: "27px",
                marginRight: "5px",
              }}
              alt="Compass Icon"
            />
            {row.coll_location}
          </div>
          <div className="col">
            <img
              src={multiple}
              style={{
                width: "16px",
                height: "16px",
                marginRight: "5px",
              }}
              alt="Multiple Icon"
            />
            {row.coll_source}
          </div>
          <div className="col">Page {row.page_number} of </div>
        </div>
      </div>

      <div className="container mt-3">pdf url: {pdfUrl}</div>
    </>
  );
};

export default PdfViewer;
