// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColStyle {
  display: flex;
  align-items: center;
}

.CollNameStyle {
  font-family: "Roboto-Slab Bold";
  font-style: normal;
  font-size: 20px;
  color: #12394d;
  align-items: center;
}

.DataStyle {
  font-family: "Roboto";
  font-style: normal;
  font-size: 16px;
}

.IconStyle {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  stroke: none;
  fill: #cc0000;
}

.ButtonStyle {
  background-color: #cc0000;
  border: none;
}

.CloseBtnStyle {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.PageNumberInput {
  width: 4rem;
  height: 2rem;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.PageNumberInput:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/styles-arabic/pdf-modal.rtl.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,0CAA0C;AAC5C","sourcesContent":[".ColStyle {\n  display: flex;\n  align-items: center;\n}\n\n.CollNameStyle {\n  font-family: \"Roboto-Slab Bold\";\n  font-style: normal;\n  font-size: 20px;\n  color: #12394d;\n  align-items: center;\n}\n\n.DataStyle {\n  font-family: \"Roboto\";\n  font-style: normal;\n  font-size: 16px;\n}\n\n.IconStyle {\n  width: 16px;\n  height: 16px;\n  margin-left: 5px;\n  stroke: none;\n  fill: #cc0000;\n}\n\n.ButtonStyle {\n  background-color: #cc0000;\n  border: none;\n}\n\n.CloseBtnStyle {\n  width: 16px;\n  height: 16px;\n  cursor: pointer;\n}\n\n.PageNumberInput {\n  width: 4rem;\n  height: 2rem;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 16px;\n}\n\n.PageNumberInput:focus {\n  outline: none;\n  border-color: #007bff;\n  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
