import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/images/logo.svg";
import theme from "../styles/theme";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "../styles/header.css";

function Header() {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/");
  };
  const changeLanguage = () => {
    localStorage.setItem("lang", "ar");
    window.location.reload();
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <nav class="navbar">
        <div onClick={handleLogoClick}>
          <img width="100%" src={logo} alt="logo" />
        </div>
        <div className="nav-items">
          <div className="give-button">
            <a
              className="btn rounded-0"
              style={{ backgroundColor: theme.colors.mainRed }}
              href="https://securelb.imodules.com/s/1209/give19/form.aspx?sid=1209&gid=214&pgid=7348&cid=11690&bledit=1&sort=1&dids=8787&appealcode=LB"
            >
              <span style={{ color: "#FFFFFF" }}>Give Now</span>&nbsp;&nbsp;
              <FontAwesomeIcon
                style={{ color: "#FFFFFF" }}
                icon={faArrowRight}
              />
            </a>
          </div>
          <div className="new-button">
            <button
              className="btn btn btn-outline-dark rounded-0 corner-button"
              data-tooltip-id="arabic-tooltip"
              data-tooltip-content="Arabic UI"
              onClick={changeLanguage}
            >
              عربي
            </button>
            <ReactTooltip id="arabic-tooltip" place="bottom" effect="solid" />
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
