import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import easel from "../../assets/icons/easel.svg";

import "../../styles-arabic/left-pane.rtl.css";

import { toArabicNumber } from "../../utils/arabicConversions";

import useLeftPane from "../../custom-hooks/useLeftPane";

const LeftPaneArabic = ({
  btn,
  setShow,
  setSearchResults,
  setSearchResultSets,
  setAnalytics,
  collTypeCounts,
  collNameIdentifier,
  collSourceCounts,
  collLocationCounts,
  searchString,
  setCurrentPage,
  setAnalyticsModalOpen,
  setLoading,
}) => {
  const {
    formatVisible,
    dateVisible,
    locationVisible,
    collectionVisible,
    sourceVisible,
    fromYear,
    toYear,
    toggleFormat,
    toggleDate,
    toggleLocation,
    toggleCollection,
    toggleSource,
    handleFromYearChange,
    handleToYearChange,
    handleFormatCheckboxChange,
    handleCollectionCheckboxChange,
    handleSourceCheckboxChange,
    handleLocationCheckboxChange,
    submitFilters,
    clearFilters,
    showMoreFormat,
    showMoreLocation,
    showMoreCollection,
    showMoreSource,
    handleAnalyticsClick,
    toggleMoreFormat,
    toggleMoreLocation,
    toggleMoreCollection,
    toggleMoreSource,
  } = useLeftPane({
    btn,
    setShow,
    setSearchResults,
    setSearchResultSets,
    setAnalytics,
    collTypeCounts,
    collNameIdentifier,
    collSourceCounts,
    collLocationCounts,
    searchString,
    setCurrentPage,
    setAnalyticsModalOpen,
    setLoading,
  });

  const toggleArrow = (isVisible, toggle) => {
    if (isVisible) {
      return (
        <div className="arabic-arrow-div" onClick={toggle}>
          <FontAwesomeIcon icon={faChevronUp} className="arabic-arrow" />
        </div>
      );
    } else {
      return (
        <div className="arabic-arrow-div" onClick={toggle}>
          <FontAwesomeIcon icon={faChevronDown} className="arabic-arrow" />
        </div>
      );
    }
  };

  return (
    <>
      <div
        className="row g-0"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {btn ? (
          <button className="analytics-button" onClick={handleAnalyticsClick}>
            <b>التحليلات</b>
            <img
              style={{
                width: "20px",
                height: "20px",
              }}
              alt=""
              src={easel}
            />
          </button>
        ) : (
          <div></div>
        )}
      </div>

      <hr className="horizontal-rule"></hr>

      <div className="row g-0">
        <div className="col g-0" onClick={toggleFormat}>
          {toggleArrow(formatVisible, toggleFormat)}
        </div>
        <div className="col g-0">نوع المنشورات</div>
      </div>
      {formatVisible && (
        <div className="row g-0">
          <div className="col g-0">
            {/* 2 checkboxes */}

            {/* Loop through collTypeCounts and display collection type and count */}
            {collTypeCounts &&
              collTypeCounts
                .slice(0, showMoreFormat ? collTypeCounts.length : 5)
                .map((collTypeCount, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span className="checkbox-count">
                        ({toArabicNumber(collTypeCount.count.toString())})
                      </span>

                      {/* <Checkbox
                          icon={
                            <div style={checkboxStyle}>
                              <FontAwesomeIcon icon={faCheck} height="10px" width="10px" size="lg" color="#fff"/>
                            </div>
                          }
                          name=""
                          onChange={(e) => handleFormatCheckboxChange( e, collTypeCount.coll_type )}
                          size={14}
                          borderWidth={0}
                          style={{ borderRadius: "0", borderColor: "#CCC", backgroundColor: "#fff" }}
                      />  */}
                      <label className="checkbox-label">
                        {collTypeCount.coll_type === "book" ? "كتاب" : "مجلة"}
                      </label>
                      <input
                        type="checkbox"
                        className="format-checkboxes"
                        onChange={(e) =>
                          handleFormatCheckboxChange(e, collTypeCount.coll_type)
                        }
                      />
                    </div>
                  );
                })}

            {collTypeCounts.length > 5 && (
              <span className="more-span" onClick={toggleMoreFormat}>
                {showMoreFormat ? "Less..." : "More..."}
              </span>
            )}
          </div>
        </div>
      )}

      <hr className="horizontal-rule"></hr>

      <div className="row mb-2 g-0">
        <div
          className="col g-0 arabic-arrow"
          style={{ cursor: "pointer" }}
          onClick={toggleDate}
        >
          {toggleArrow(dateVisible, toggleDate)}
        </div>
        <div className="col g-0">تاريخ النشر</div>
      </div>
      {dateVisible && (
        <>
          <div className="row g-0">
            <div
              className="col g-0"
              style={{ fontWeight: "bold", transform: "translateX(15px)" }}
            >
              {" "}
              إلى{" "}
            </div>
            <div className="col g-0"></div>
            <div
              className="col g-0"
              style={{ fontWeight: "bold", transform: "translateX(20px)" }}
            >
              {" "}
              من{" "}
            </div>
          </div>

          <div className="row g-0" style={{ display: "flex" }}>
            <div className="col g-0">
              <input
                type="text"
                placeholder="YYYY"
                onChange={handleToYearChange}
                value={toYear}
                style={{
                  width: "100px",
                  transform: "translateX(-5px)",
                  outline: "none",
                }}
              />
            </div>
            <div
              className="col g-0"
              style={{
                display: "flex",
                justifyContent: "center",
                transform: "translateX(-10px",
              }}
            >
              {" "}
              -{" "}
            </div>
            <div className="col g-0">
              <input
                type="text"
                placeholder="YYYY"
                onChange={handleFromYearChange}
                value={fromYear}
                style={{ width: "100px", outline: "none" }}
              />
            </div>
          </div>
        </>

        // <div className="row g-0">
        //   <div className="col g-0">
        //     <div className="col g-0" className="checkbox-label">From</div>
        //     <div className="col mb-2 g-0">
        //       <input
        //         type="text"
        //         placeholder="YYYY"
        //         onChange={handleFromYearChange}
        //         value={fromYear}
        //       />
        //     </div>
        //   </div>
        //   <div className="col g-0">
        //     <div className="col g-0" className="checkbox-label">To</div>
        //     <div className="col g-0" style={{ display: "flex" }}>
        //       <input
        //         type="text"
        //         placeholder="YYYY"
        //         onChange={handleToYearChange}
        //         value={toYear}
        //       />
        //     </div>
        //   </div>
        // </div>
      )}

      <hr className="horizontal-rule"></hr>

      <div className="row mb-2 g-0">
        <div className="col g-0" onClick={toggleLocation}>
          {toggleArrow(locationVisible, toggleLocation)}
        </div>
        <div className="col g-0">مكان النشر </div>
      </div>
      {locationVisible && (
        <div className="row g-0">
          <div className="col-pt-10 g-0">
            {/* Loop through collLocationCounts and display collection type and count */}
            {collLocationCounts &&
              collLocationCounts
                .slice(0, showMoreLocation ? collLocationCounts.length : 5)
                .map((collLocationCount, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span className="checkbox-count">
                        {/* ({collLocationCount.count}) */}(
                        {toArabicNumber(collLocationCount.count.toString())})
                      </span>
                      <label className="checkbox-label">
                        {collLocationCount.coll_location_arabic}
                      </label>

                      <input
                        type="checkbox"
                        className="format-checkboxes"
                        onChange={(e) =>
                          handleLocationCheckboxChange(
                            e,
                            collLocationCount.coll_location_arabic
                          )
                        }
                      />
                      {/* <Checkbox
                            icon={
                              <div style={checkboxStyle}>
                                <FontAwesomeIcon icon={faCheck} height="10px" width="10px" size="lg" color="#fff"/>
                              </div>
                            }
                            name="my-input"
                            onChange={(e) =>
                              handleLocationCheckboxChange(
                                e,
                                collLocationCount.coll_location
                              )
                            }
                            size={14}
                            borderWidth={0}
                            style={{ borderRadius: "0", borderColor: "#CCC", backgroundColor: "#fff" }} />  */}
                    </div>
                  );
                })}
            {collLocationCounts.length > 5 && (
              <span className="more-span" onClick={toggleMoreLocation}>
                {showMoreLocation ? "Less..." : "More..."}
              </span>
            )}
          </div>
        </div>
      )}

      <hr className="horizontal-rule"></hr>

      <div className="row mb-2 g-0">
        <div className="col g-0" onClick={toggleCollection}>
          {toggleArrow(collectionVisible, toggleCollection)}
        </div>
        <div className="col g-0">المجموعة</div>
      </div>
      {collectionVisible && (
        <div className="row mt-2 g-0">
          <div className="col g-0">
            {collNameIdentifier &&
              collNameIdentifier
                .slice(0, showMoreCollection ? collNameIdentifier.length : 5)
                .map((collNameCount, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span className="checkbox-count">
                        {/* ({collNameCount.count}) */}(
                        {toArabicNumber(collNameCount.count.toString())})
                      </span>
                      <label className="checkbox-label">
                        {collNameCount.coll_name_arabic}
                      </label>

                      <input
                        type="checkbox"
                        className="format-checkboxes"
                        onChange={(e) =>
                          handleCollectionCheckboxChange(
                            e,
                            collNameCount.coll_name_arabic
                          )
                        }
                      />

                      {/* <Checkbox
                        icon={
                          <div style={checkboxStyle}>
                            <FontAwesomeIcon icon={faCheck} height="10px" width="10px" size="lg" color="#fff"/>
                          </div>
                        }
                        name="my-input"
                        onChange={(e) => handleCollectionCheckboxChange( e, collNameCount.coll_name )}
                        size={14}
                        borderWidth={0}
                        style={{ borderRadius: "0", borderColor: "#CCC", backgroundColor: "#fff" }}
                      /> */}
                    </div>
                  );
                })}

            {collNameIdentifier.length > 5 && (
              <span className="more-span" onClick={toggleMoreCollection}>
                {showMoreCollection ? "Less..." : "More..."}
              </span>
            )}
          </div>
        </div>
      )}

      <hr className="horizontal-rule"></hr>

      <div>
        <div className="row mb-2 g-0">
          <div className="col g-0" onClick={toggleSource}>
            {toggleArrow(sourceVisible, toggleSource)}
          </div>
          <div className="col g-0">المصدر</div>
        </div>
        {sourceVisible && (
          <div className="row mb-4 g-0">
            <div className="col g-0">
              {/* Loop through collSourceCounts and display collection type and count */}
              {collSourceCounts &&
                collSourceCounts
                  .slice(0, showMoreSource ? collSourceCounts.length : 5)
                  .map((collSourceCount, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <span className="checkbox-count">
                          {/* ({collSourceCount.count}) */}(
                          {toArabicNumber(collSourceCount.count.toString())})
                        </span>
                        <label className="checkbox-label">
                          {collSourceCount.coll_source_arabic}
                        </label>

                        <input
                          type="checkbox"
                          className="format-checkboxes"
                          onChange={(e) =>
                            handleSourceCheckboxChange(
                              e,
                              collSourceCount.coll_source_arabic
                            )
                          }
                        />

                        {/* <Checkbox
                          icon={
                            <div style={checkboxStyle}>
                              <FontAwesomeIcon icon={faCheck} height="10px" width="10px" size="lg" color="#fff"/>
                            </div>
                          }
                          name="my-input"
                          onChange={(e) => handleSourceCheckboxChange( e, collSourceCount.coll_source )}
                          size={14}
                          borderWidth={0}
                          style={{ borderRadius: "0", borderColor: "#CCC", backgroundColor: "#fff" }}
                        />  */}
                      </div>
                    );
                  })}

              {collSourceCounts.length > 5 && (
                <span className="more-span" onClick={toggleMoreSource}>
                  {showMoreSource ? "Less..." : "More..."}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="apply-clear-buttons row g-0">
        <button className="clear-button" onClick={clearFilters}>
          مسح الفلتر
        </button>

        <button className="apply-button" onClick={submitFilters}>
          ← تطبيق الفلتر
        </button>
      </div>
    </>
  );
};

export default LeftPaneArabic;
