import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { browseAPI } from "../axios/axios";

import Checkbox from "react-custom-checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Dash } from "../assets/icons/dash.svg";

import theme from "../styles/theme";

import "../styles/analytics.css";
import "../styles/browse.css";

import { toast } from "react-toastify";
import useYearInputValidation from "../custom-hooks/useYearInputValidation";
import GenericModal from "./GenericModal";
import useBrowseFiltersBooks from "../custom-hooks/useBrowseFiltersBooks";

const BrowseFiltersBooks = ({
  setBrowseResults,
  setCurrentPage,
  setLoading,
}) => {
  const {
    authors,
    titles,
    locations,
    publishers,
    volumes,
    selectedAuthors,
    selectedTitles,
    selectedLocations,
    selectedPublishers,
    selectedVolumes,
    selectedKeys1,
    selectedKeys2,
    selectedKeys3,
    selectedKeys4,
    selectedKeys5,
    unselectedKeys1,
    unselectedKeys2,
    unselectedKeys3,
    unselectedKeys4,
    unselectedKeys5,
    maxRowsToShow,
    showMoreAuthor,
    showMoreTitle,
    showMoreLocation,
    showMorePublisher,
    showMoreVolume,
    fromYear,
    toYear,
    minYear,
    maxYear,
    handleAuthorCheckboxChange,
    handleTitleCheckboxChange,
    handleLocationCheckboxChange,
    handlePublisherCheckboxChange,
    handleVolumeCheckboxChange,
    handleFromYearChange,
    handleToYearChange,
    handleCloseAuthorModal,
    handleCloseTitleModal,
    handleCloseLocationModal,
    handleClosePublisherModal,
    handleCloseVolumeModal,
    handleApply,
    clearFilters,
    setShowMoreAuthor,
    setShowMoreLocation,
    setShowMorePublisher,
    setShowMoreTitle,
  } = useBrowseFiltersBooks({ setBrowseResults, setCurrentPage, setLoading });
  return (
    <div style={{ background: theme.colors.mainGrayBackground, width: "100%" }}>
      <div className="row g-0">
        <div className="col g-0">
          <div
            className="heading"
            style={{
              padding: "3rem 0",
              justifyContent: "center",
            }}
          >
            <h2
              className="browse-header"
              style={{
                fontFamily: "Roboto-Slab",
                fontSize: "34px",
              }}
            >
              Browse our Collections
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        {/*--------AUTHOR--------------------*/}
        <div class="col-sm new-heading">
          <div>Author Name</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected collections first */}
            {selectedKeys5.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedAuthors[key] || false}
                  onChange={(e) => handleAuthorCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected collections if there are remaining slots */}
            {selectedKeys5.length < maxRowsToShow &&
              unselectedKeys5
                .slice(0, maxRowsToShow - selectedKeys5.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedAuthors[key] || false}
                      onChange={(e) => handleAuthorCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys5.length > maxRowsToShow - selectedKeys5.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreAuthor(true);
                }}
              >
                {showMoreAuthor ? "Less..." : "More..."}
              </span>
            )}

            <GenericModal
              title="Authors"
              show={showMoreAuthor}
              onHide={handleCloseAuthorModal}
              data={authors}
              selectedData={selectedAuthors}
              handleItemCheckboxChange={handleAuthorCheckboxChange}
            />
          </div>
        </div>
        {/* ------------------TITLE-------------------- */}
        <div class="col-sm new-heading">
          <div>Title</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected titles first */}
            {selectedKeys1.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedTitles[key] || false}
                  onChange={(e) => handleTitleCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected titles if there are remaining slots */}
            {selectedKeys1.length < maxRowsToShow &&
              unselectedKeys1
                .slice(0, maxRowsToShow - selectedKeys1.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedTitles[key] || false}
                      onChange={(e) => handleTitleCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys1.length > maxRowsToShow - selectedKeys1.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreTitle(true);
                }}
              >
                {showMoreTitle ? "Less..." : "More..."}
              </span>
            )}

            <GenericModal
              title="Titles"
              show={showMoreTitle}
              onHide={handleCloseTitleModal}
              data={titles}
              selectedData={selectedTitles}
              handleItemCheckboxChange={handleTitleCheckboxChange}
            />
          </div>
        </div>

        {/* ------------------PLCAE OF PUBLICATION-------------------- */}
        <div class="col-sm new-heading">
          <div>Place of Publication</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected collections first */}
            {selectedKeys2.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedLocations[key] || false}
                  onChange={(e) => handleLocationCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected collections if there are remaining slots */}
            {selectedKeys2.length < maxRowsToShow &&
              unselectedKeys2
                .slice(0, maxRowsToShow - selectedKeys2.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedLocations[key] || false}
                      onChange={(e) => handleLocationCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys2.length > maxRowsToShow - selectedKeys2.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreLocation(true);
                }}
              >
                {showMoreLocation ? "Less..." : "More..."}
              </span>
            )}

            <GenericModal
              title="Locations"
              show={showMoreLocation}
              onHide={handleCloseLocationModal}
              data={locations}
              selectedData={selectedLocations}
              handleItemCheckboxChange={handleLocationCheckboxChange}
            />
          </div>
        </div>

        {/* ------------------PUBLISHER-------------------- */}
        <div class="col-sm new-heading">
          <div>Publisher</div>
          <div style={{ fontSize: "1rem", color: "#212529" }}>
            {/* Display selected publishers first */}
            {selectedKeys4.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedPublishers[key] || false}
                  onChange={(e) => handlePublisherCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {/* Display unselected collections if there are remaining slots */}
            {selectedKeys4.length < maxRowsToShow &&
              unselectedKeys4
                .slice(0, maxRowsToShow - selectedKeys4.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedPublishers[key] || false}
                      onChange={(e) => handlePublisherCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {/* Show "More..." link only if there are unselected collections remaining */}
            {unselectedKeys4.length > maxRowsToShow - selectedKeys4.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMorePublisher(true);
                }}
              >
                {showMorePublisher ? "Less..." : "More..."}
              </span>
            )}

            <GenericModal
              title="Publishers"
              show={showMorePublisher}
              onHide={handleClosePublisherModal}
              data={publishers}
              selectedData={selectedPublishers}
              handleItemCheckboxChange={handlePublisherCheckboxChange}
            />
          </div>
        </div>

        {/* ------------------DATE-------------------- */}
        <div class="col-sm new-heading" style={{ paddingBottom: "45px" }}>
          <div style={{ paddingBottom: "20px" }}>Date of Publication</div>
          <div
            className="date-input"
            style={{ fontSize: "1rem", color: "#212529" }}
          >
            <input
              type="text"
              placeholder={minYear === "" ? "YYYY" : minYear}
              onChange={handleFromYearChange}
              value={fromYear}
              style={{ width: "3em", outline: "none" }}
            />
            <Dash />
            <input
              type="text"
              placeholder={maxYear === "" ? "YYYY" : maxYear}
              onChange={handleToYearChange}
              value={toYear}
              style={{ width: "3em", outline: "none" }}
            />
          </div>
        </div>
      </div>
      {/* ------------------VOLUME-------------------- */}
      {/* <div className="col-2 g-0" style={{ borderRight: "2px solid #bdbdbd" }}>
          <div className="row g-0">
            <div style={{ paddingLeft: "4rem" }}>
              <h5 className="subheading">Volumes</h5>
            </div>
          </div>
          <div
            className="row g-0"
            style={{
              padding: "0 1rem",
              minHeight: "96px",
              display: "flex",
              paddingLeft: "4rem",
            }}
          >

            {selectedKeys3.slice(0, maxRowsToShow).map(({ key, value }) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <div className="checkbox">
                      <FontAwesomeIcon
                        icon={faCheck}
                        height="10px"
                        width="10px"
                        size="lg"
                        color="#fff"
                      />
                    </div>
                  }
                  name="my-input"
                  checked={selectedVolumes[key] || false}
                  onChange={(e) => handleVolumeCheckboxChange(e, key)}
                  size={14}
                  borderWidth={0}
                  style={{
                    borderRadius: "0",
                    borderColor: "#CCC",
                    backgroundColor: "#fff",
                  }}
                />
                <label className="checkbox-label-browse">{value}</label>
              </div>
            ))}

            {selectedKeys3.length < maxRowsToShow &&
              unselectedKeys3
                .slice(0, maxRowsToShow - selectedKeys3.length)
                .map(([key, value]) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      icon={
                        <div className="checkbox">
                          <FontAwesomeIcon
                            icon={faCheck}
                            height="10px"
                            width="10px"
                            size="lg"
                            color="#fff"
                          />
                        </div>
                      }
                      name="my-input"
                      checked={selectedVolumes[key] || false}
                      onChange={(e) => handleVolumeCheckboxChange(e, key)}
                      size={14}
                      borderWidth={0}
                      style={{
                        borderRadius: "0",
                        borderColor: "#CCC",
                        backgroundColor: "#fff",
                      }}
                    />
                    <label className="checkbox-label-browse">{value}</label>
                  </div>
                ))}

            {unselectedKeys3.length > maxRowsToShow - selectedKeys3.length && (
              <span
                className="more-span"
                onClick={() => {
                  setShowMoreVolume(true);
                }}
              >
                {showMoreVolume ? "Less..." : "More..."}
              </span>
            )}

            <GenericModal
              title="Volumes"
              show={showMoreVolume}
              onHide={handleCloseVolumeModal}
              data={volumes}
              selectedData={selectedVolumes}
              handleItemCheckboxChange={handleVolumeCheckboxChange}
            />
          </div>
        </div> */}

      <div className="row g-0">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "1rem",
          }}
        >
          <button
            className="btn rounded-0 apply-button"
            style={{
              margin: "3px 3px",
              width: "70px",
              height: "35px",
            }}
            onClick={handleApply}
          >
            <span>Apply</span>
          </button>

          <button
            className="btn rounded-0 clear-button"
            style={{
              margin: "3px 3px",
              width: "70px",
              height: "35px",
            }}
            onClick={clearFilters}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrowseFiltersBooks;
