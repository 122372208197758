import ReactGA from 'react-ga4';

const InitGoogleAnalytics = () => {
    try {
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
          gaOptions: {
            debug_mode: true,
          },
          gtagOptions: {
            debug_mode: true,
          },
        });
    } catch (error) {
        console.error("Google Analytics init")
        console.error(error);
    }
}

const TrackGAEvent = (
    name,
    params
) => {
    try {
        ReactGA.event(
            name = name,
            params = params
        );
    } catch (error) {
        console.log("Error sending GA event")
        console.log(error)
    }
    console.log("GA EVent Fired", name, params)
}

export default InitGoogleAnalytics;
export {InitGoogleAnalytics, TrackGAEvent};