import theme from "../styles/theme";
import React, { useState, useRef, useEffect } from "react";
import { components } from "react-select";
import { useNavigate } from "react-router-dom";

import "../styles-arabic/search-input.rtl.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import { searchAPI } from "../axios/search-axios";

import "react-simple-keyboard/build/css/index.css";

import { toast } from "react-toastify";
import { auto } from "@popperjs/core";

import { debounce } from "lodash";
import { TrackGAEvent } from "../google-analytics/gautil";

const useSearchInput = ({
  searchString,
  setSearchResults,
  setSearchResultSets,
  setCurrentPage,
  setAnalytics,
  setLoading,
  autocompleteRef,
}) => {
  const filterStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF",
      border: "1px solid #CCCCCC",
      borderRadius: "36px",
      marginRight: "0.7em",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "1em",
      fontWeight: 400,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? theme.colors.mainRed
        : isFocused
        ? theme.colors.mainGrayBackground
        : undefined,
    }),
  };

  const [searchInput, setSearchInput] = useState(
    localStorage.getItem("searchTerm")
      ? JSON.parse(localStorage.getItem("searchTerm"))
      : ""
  );
  const [isHovered, setIsHovered] = useState(false);
  const [matchType, setMatchType] = useState(
    localStorage.getItem("matchType")
      ? localStorage.getItem("matchType").replace(/^"(.*)"$/, "$1")
      : "any"
  );
  const [keyboardVisibility, setkeyboardVisibility] = useState(false);
  const [layoutName, setLayoutName] = useState("default");

  const navigate = useNavigate();
  const keyboard = useRef();

  const handleMatchTypeChange = (event) => {
    setMatchType(event.value);
    localStorage.setItem("matchType", JSON.stringify(event.value));
  };

  const lang = localStorage.getItem("lang");
  const noResultsMessage =
    lang === "ar" ? "الرجاء إدخال كلمة بحث" : "No results found";

  const handleSearch = async () => {
    setLoading(true);

    //Send the search term to the backend if string is not empty

    if (searchInput === "") {
      setLoading(false);
      toast.warn("Please enter a search term", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return;
    }
    let searchBackend = {
      search_term: searchInput,
      match_type: matchType,
    };
    let res = await searchAPI.put("", searchBackend);
    // setSearchResults(res.data.docs);

    TrackGAEvent("search", { search_term: searchBackend.search_term });

    if (res.data.docs.length === 0) {
      setLoading(false);
      toast.warn(`${noResultsMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      return;
    }

    res.data?.docs?.forEach((doc) => {
      doc.is_download_blocked = res.data?.download_blocked_list?.includes(
        doc.coll_identifier
      );
    });

    try {
      localStorage.setItem("searchResults", JSON.stringify(res.data.docs));
    } catch (e) {
      if (e instanceof DOMException && e.name === "QuotaExceededError") {
        try {
          localStorage.removeItem("searchResults");
          localStorage.clear();
          // Set other items back to local storage
          localStorage.setItem("searchTerm", JSON.stringify(searchInput));
          localStorage.setItem("matchType", JSON.stringify(matchType));
          localStorage.setItem("searchResults", JSON.stringify(res.data.docs));
          // Success handling, possibly a success message or log
        } catch (e) {
          toast.error("Could not save search results. Please try again.", {});
          localStorage.clear();
        }
      } else {
        toast.error("An error occurred. Please try again.", {});
        localStorage.clear();
      }
    }

    localStorage.setItem("analytics", JSON.stringify(res.data.analytics));
    localStorage.setItem("searchItemsSet", JSON.stringify(false));
    localStorage.setItem("searchTerm", JSON.stringify(searchInput));
    localStorage.setItem("matchType", JSON.stringify(matchType));

    if (setSearchResults) {
      setSearchResults(res.data.docs);
      setAnalytics(res.data.analytics);
      setSearchResultSets((prevSearchResultSets) => ({
        [1]: res.data.docs,
      }));
    }

    if (setCurrentPage) {
      setCurrentPage(1);
    }

    navigate("/search-results", {
      state: {
        searchString: searchInput,
        searchResults: res.data.docs,
        analytics: res.data.analytics,
        totalCount: res.data.count,
        searchAfter: res.data.search_after,
        pageNumber: 1,
      },
    });
    setLoading(false);
  };

  library.add(faChevronDown);

  const filterOptions =
    lang === "ar"
      ? [
          {
            label: "أي كلمة",
            value: "any",
          },
          {
            label: "جميع الكلمات",
            value: "all",
          },
          {
            label: "الكلمة الدقيقة",
            value: "exact",
          },
        ]
      : [
          {
            label: "Any Word",
            value: "any",
          },
          {
            label: "All Words",
            value: "all",
          },
          {
            label: "Exact Word",
            value: "exact",
          },
        ];

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };

  const CaretDownIcon = () => {
    return <FontAwesomeIcon icon="chevron-down" className="option-arrow" />;
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  // ----------------Arabic Keyboard----------------

  const toggleKeyboard = () => {
    setkeyboardVisibility(!keyboardVisibility);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
    if (button === "{enter}") handleSearch();
    if (button === "{bksp}") setSearchInput(searchInput.slice(0, -1));
  };

  const handleShift = () => {
    setLayoutName((prevLayoutName) =>
      prevLayoutName === "default" ? "shift" : "default"
    );
  };

  const matchTypeFromLocalStorage = localStorage.getItem("matchType")
    ? localStorage.getItem("matchType").replace(/^"(.*)"$/, "$1")
    : "any";

  const defaultValueOption = filterOptions.find(
    (option) => option.value === matchTypeFromLocalStorage
  );

  const defaultValue = defaultValueOption ? defaultValueOption : null;

  // Autocomplete feature

  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);

  const debouncedAutoComplete = debounce(async (input) => {
    const words = input.trim().split(/\s+/).slice(-5);
    const autocompleteText = words.join(" ");
    if (autocompleteText.length > 2) {
      try {
        const res = await searchAPI.put(
          `autocomplete?autocomplete_text=${autocompleteText}`
        );
        // Filter and clean suggestions
        const filteredSuggestions = res.data.map((suggestion) => {
          let cleanedSuggestion = cleanSuggestion(suggestion);
          if (
            !autocompleteText.startsWith("ال") &&
            cleanedSuggestion.startsWith("ال")
          ) {
            cleanedSuggestion = cleanedSuggestion.replace("ال", "");
          }
          return cleanedSuggestion;
        });
        setSuggestions(filteredSuggestions);
        setShowSuggestions(true);
      } catch (error) {
        console.error("Failed to fetch autocomplete suggestions", error);
        setSuggestions([]);
      }
    } else {
      // Clear if last word too short
      setSuggestions([]);
    }
  }, 250);

  const onChangeInput = async (event) => {
    const input = event.target.value;
    setSearchInput(input);
    debouncedAutoComplete(input);
    if (keyboard && keyboard.current) keyboard.current.setInput(input);
  };

  const onChangeArabicKeyboard = (input) => {
    setSearchInput(input);
    debouncedAutoComplete(input);
    if (keyboard && keyboard.current) keyboard.current.setInput(input);
  };

  useEffect(() => {
    return () => {
      debouncedAutoComplete.cancel();
    };
  }, []);

  // Autocomplete dropdown close on click outside

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [autocompleteRef, setShowSuggestions]);

  const handleSuggestionClick = (suggestion) => {
    setShowSuggestions(false);
    // Set the entire input to = suggestion
    setSearchInput(suggestion);
    if (keyboard && keyboard.current) keyboard.current.setInput(suggestion);
  };

  // Cleanup suggestions on Production
  const cleanSuggestion = (suggestion) => {
    // Replace any no. of - or + with a single space
    return suggestion.replace(/[-+]+/g, " ").replace(/\s+/g, " ").trim();
  };

  return {
    searchInput,
    setSearchInput,
    isHovered,
    setIsHovered,
    matchType,
    setMatchType,
    keyboardVisibility,
    setkeyboardVisibility,
    layoutName,
    setLayoutName,
    filterStyles,
    filterOptions,
    handleSearch,
    handleMatchTypeChange,
    onChangeInput,
    toggleKeyboard,
    onChangeArabicKeyboard,
    onKeyPress,
    handleShift,
    defaultValue,
    Placeholder,
    DropdownIndicator,
    keyboard,
    suggestions,
    setSuggestions,
    showSuggestions,
    setShowSuggestions,
    handleSuggestionClick,
  };
};

export default useSearchInput;
