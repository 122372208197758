import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { statsAPI } from "../axios/stats-axios";

const useHome = () => {
  const [loading, setLoading] = useState(false);
  const [collections, setCollections] = useState(0);
  const [pages, setPages] = useState(0);
  const [documents, setDocuments] = useState(0);
  const [focus, setFocus] = useState(false);
  const navigate = useNavigate();

  const handleBrowsePeriodicalClick = async () => {
    setLoading(true);
    navigate("/browse-periodicals", {
      state: { coll_type: "periodical", browseResults: [] },
    });
    setLoading(false);
  };

  const handleBrowseBooksClick = async () => {
    setLoading(true);
    navigate("/browse-books", {
      state: { coll_type: "book", browseResults: [] },
    });
    setLoading(false);
  };

  useEffect(() => {
    localStorage.setItem("searchTerm", JSON.stringify(""));
    localStorage.setItem("searchResults", JSON.stringify([]));
    localStorage.setItem("matchType", JSON.stringify("any"));
    localStorage.setItem("searchItemsSet", JSON.stringify(false));
    localStorage.setItem("analytics", JSON.stringify({}));
    localStorage.setItem("filters", null);
  }, []);

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    try {
      const response = await statsAPI.get("");
      const { collections, pages, documents } = response.data;
      setCollections(collections);
      setPages(pages);
      setDocuments(documents);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    loading,
    setLoading,
    collections,
    pages,
    documents,
    focus,
    setFocus,
    handleBrowseBooksClick,
    handleBrowsePeriodicalClick,
    getStats,
    navigate,
  };
};

export default useHome;
