import React from "react";
import logo from "../assets/gifs/loading.gif";

import "../styles/loading.css";

const Loading = ({ isLoading, children }) => {
  return (
    <>
      <div className={isLoading ? "BlurredStyle" : ""}>{children}</div>
      {isLoading && (
        <div className="overlay OverlayStyle">
          <img src={logo} alt="Loading GIF" className="LoadingImgStyle" />
        </div>
      )}
    </>
  );
};

export default Loading;
