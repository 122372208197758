import React, { useRef } from "react";
import Select from "react-select";
import "../styles/search-input.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faKeyboard } from "@fortawesome/free-solid-svg-icons";
import arrowLeftUp from "../assets/icons/Union.svg";
import Keyboard from "react-simple-keyboard";
import arabic from "simple-keyboard-layouts/build/layouts/arabic";
import "react-simple-keyboard/build/css/index.css";
import useSearchInput from "../custom-hooks/useSearchInput";

const SearchInput = ({
  searchString,
  setSearchResults,
  setSearchResultSets,
  setCurrentPage,
  setAnalytics,
  setLoading,
}) => {
  const autocompleteRef = useRef(null);

  const {
    searchInput,
    setIsHovered,
    keyboardVisibility,
    onChangeInput,
    handleSearch,
    toggleKeyboard,
    handleMatchTypeChange,
    keyboard,
    layoutName,
    onChangeArabicKeyboard,
    onKeyPress,
    Placeholder,
    DropdownIndicator,
    filterOptions,
    defaultValue,
    filterStyles,
    suggestions,
    showSuggestions,
    setShowSuggestions,
    handleSuggestionClick,
  } = useSearchInput({
    searchString,
    setSearchResults,
    setSearchResultSets,
    setCurrentPage,
    setAnalytics,
    setLoading,
    autocompleteRef,
  });

  return (
    <>
      <div className="row g-0">
        <div className="col search-div">
          <div className="search-bar">
            <span onClick={toggleKeyboard}>
              <FontAwesomeIcon className="keyboard-icon" icon={faKeyboard} />
            </span>
            <input
              value={searchInput}
              className="text-input"
              type="text"
              placeholder={searchString ? searchString : "Search by keyword"}
              onChange={onChangeInput}
              onKeyDown={(event) => {
                if (event.key === "Enter" || event.keyCode === 13) {
                  handleSearch();
                }
              }}
            />
            <div>
              <Select
                closeMenuOnSelect={false}
                components={{ Placeholder, DropdownIndicator }}
                placeholder={"Choose"}
                styles={filterStyles}
                options={filterOptions}
                blurInputOnSelect={true}
                defaultValue={defaultValue}
                onChange={handleMatchTypeChange}
              />
            </div>

            <FontAwesomeIcon
              className="search-icon"
              icon={faSearch}
              onClick={handleSearch}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
          </div>
        </div>
      </div>
      <div className="row g-0 below-input">
        <div ref={autocompleteRef} className="autocomplete-div">
          {/* Autocomplete suggestions */}
          {showSuggestions && suggestions && suggestions.length > 0 && (
            <ul className="autocomplete-suggestions">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="col text-below-search-box-div">
          <img
            className="left-up-arrow"
            src={arrowLeftUp}
            alt="Arrow Left Up"
          ></img>
          <p>You can use this Arabic keyboard</p>
        </div>
        <div className="col"></div>
      </div>

      <div className="row arabic-keyboard">
        <div className="col-sm-2"></div>
        <div className="col-sm-8">
          {keyboardVisibility && (
            <Keyboard
              keyboardRef={keyboard.current}
              layoutName={layoutName}
              layout={arabic.layout}
              onChange={onChangeArabicKeyboard}
              onKeyPress={onKeyPress}
            />
          )}
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );
};

export default SearchInput;
