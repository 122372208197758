import React, { useState } from "react";
import "../styles/analytics.css";
import { feedbackAPI } from "../axios/feedback_axios";

const useFeedbackPopup = ({ show, handleClose, fingerprint }) => {
  const [feature_request, setFeature_request] = useState("");
  const [collection_request, setCollection_request] = useState("");

  const handleSubmit = () => {
    const feedbackData = {
      feature_request: feature_request,
      collection_request: collection_request,
      user_fingerprint: fingerprint, //Include the fingerprint
    };

    feedbackAPI
      .put("/add_feedback", feedbackData)
      .then((response) => {
        //  success

        handleClose();
      })
      .catch((error) => {
        // error
        console.error("Error submitting feedback:", error);
      });
  };
  return {
    feature_request,
    setFeature_request,
    collection_request,
    setCollection_request,
    handleSubmit,
  };
};

export default useFeedbackPopup;
