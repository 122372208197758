const theme = {
  colors: {
    mainGrayBackground: "#E5E5E5",
    fontDarkBlue: "#12394D",
    fontLightBlue: "#427E93",
    mainRed: "#CC0000",
  },
};

export default theme;
