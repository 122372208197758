import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo.svg";
import theme from "../../styles/theme";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "../../styles-arabic/header.rtl.css";

function Header() {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate("/");
  };
  const changeLanguage = () => {
    localStorage.setItem("lang", "eng");
    window.location.reload();
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <nav class="navbar">
        <div onClick={handleLogoClick}>
          <img width="100%" src={logo} alt="logo" />
        </div>
        <div className="nav-items" dir="rtl">
          <div className="arabic-give-button">
            <a
              className="btn rounded-0"
              style={{ backgroundColor: theme.colors.mainRed }}
              href="https://securelb.imodules.com/s/1209/give19/form.aspx?sid=1209&gid=214&pgid=7348&cid=11690&bledit=1&sort=1&dids=8787&appealcode=LB"
            >
              <span style={{ color: "#FFFFFF" }}> تبرّع </span>&nbsp;&nbsp;
              <FontAwesomeIcon
                style={{ color: "#FFFFFF" }}
                icon={faArrowLeft}
              />
            </a>
          </div>
          <div className="arabic-new-button">
            <button
              className="btn btn btn-outline-dark rounded-0 arabic-corner-button"
              data-tooltip-id="arabic-tooltip"
              onClick={changeLanguage}
            >
              English
            </button>
            <ReactTooltip id="arabic-tooltip" place="bottom" effect="solid" />
          </div>
        </div>
        {/* <div className="arabic-new-button">
          <button
            className="btn btn btn-outline-dark rounded-0 corner-button"
            data-tooltip-id="arabic-tooltip"
            onClick={changeLanguage}
          >
            English
          </button>
          <ReactTooltip id="arabic-tooltip" place="bottom" effect="solid" />
        </div>

        <div className="nav-items">
          <div className="arabic-give-button">
            <a
              className="btn rounded-0"
              style={{ backgroundColor: theme.colors.mainRed }}
              href="https://securelb.imodules.com/s/1209/give19/form.aspx?sid=1209&gid=214&pgid=7348&cid=11690&bledit=1&sort=1&dids=8787&appealcode=LB"
            >
              <FontAwesomeIcon
                style={{ color: "#FFFFFF" }}
                icon={faArrowLeft}
              />
              <span style={{ color: "#FFFFFF" }}> تبرّع </span>&nbsp;&nbsp;
            </a>
          </div>
        </div> */}
      </nav>
    </div>
  );
}

export default Header;
