import React, { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { feedbackAPI } from "../axios/feedback_axios";

const useFeedbackMonitor = () => {
  //feedbackpopup
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [fingerprint, setFingerprint] = useState(null);
  const [isNewUser, setIsNewUser] = useState(null); // track if the user is new

  useEffect(() => {
    const loadFingerprintJS = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();

      setFingerprint(result.visitorId);

      checkIfNewUser(result.visitorId);
    };

    loadFingerprintJS();
  }, []);

  const checkIfNewUser = (fingerprint) => {
    // Call the backend API to check if it's a new user
    feedbackAPI
      .get("/check_feedback_exists", {
        params: { user_fingerprint: fingerprint },
      })
      .then((response) => {
        const feedbackExists = response.data.feedback_exists;

        setIsNewUser(!feedbackExists);
      })
      .catch((error) => {
        console.error("Error checking if user is new:", error);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFeedbackPopup(true);
    }, 60000); // 60 seconds

    return () => clearTimeout(timer);
  }, []);
  const handleCloseFeedbackPopup = () => {
    setShowFeedbackPopup(false);
  };

  return {
    showFeedbackPopup,
    handleCloseFeedbackPopup,
    fingerprint,
    isNewUser,
  };
};

export default useFeedbackMonitor;
